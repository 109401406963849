import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

export interface ContactResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      number: string;
      address: string;
    },
  ];
}

export interface ContactProps {
  id: number;
  name: string;
  id_name: string;
  number: string;
  address: string;
}

interface ContactsContextData {
  pageSize: number;
  handleChangePageSize: (value: number) => void;
  contactsList?: ContactProps[];
  totalContacts: number;
  hasMore: boolean;
  moreContacts: (page: number, pageSize: number) => void;
  isLoading: boolean;
  quickSearch: string;
  setNewQuickSearch: (value: string) => void;
  refetch: () => void;
  isFetching: boolean;
}
const ContactsContext = createContext<ContactsContextData>(
  {} as ContactsContextData,
);

const ContactsProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();

  const [totalContacts, setTotalContacts] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [limit, setLimit] = useState(pageSize);
  const [hasMore, setHasMore] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [quickSearch, setQuickSearch] = useState<string>('');

  const { data: contactsList, refetch, isFetching } = useQuery(
    [`contactsList`, pageSize, limit, environment?.id, quickSearch],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get<ContactResponseProps>(
        `/zc/${environment.id}/client/`,
        {
          params: {
            offset: limit - pageSize,
            limit: pageSize,
            ...(quickSearch
              ? quickSearch.match(/\d/) != null
                ? { number__icontains: quickSearch }
                : { name__icontains: quickSearch }
              : {}),
          },
        },
      );

      const { data } = response;

      setHasMore(!!data.next);
      setTotalContacts(data.count);

      const contactsListResponse = data.results.map(contact => {
        const contactResponse: ContactProps = {
          ...contact,
          id_name: `#${contact.id} - ${contact.name}`,
        };

        return contactResponse;
      });

      setIsLoading(false);
      return contactsListResponse;
    },
  );

  const moreContacts = (page: number, size: number) => {
    setLimit(page * size);
    setPageSize(size);
  };

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  return (
    <ContactsContext.Provider
      value={{
        pageSize,
        handleChangePageSize,
        contactsList,
        totalContacts,
        hasMore,
        moreContacts,
        isLoading,
        quickSearch,
        setNewQuickSearch,
        refetch,
        isFetching,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
function useContacts(): ContactsContextData {
  const context = useContext(ContactsContext);

  if (!context) {
    throw new Error('useContacts must be used within an ContactsProvider');
  }

  return context;
}

export { ContactsProvider, useContacts };
