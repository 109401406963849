import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  img {
    margin: 20px 0;
  }

  form {
    margin: 20px 0;
    width: 340px;
    text-align: center;
  }
`;
