import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import intl from 'react-intl-universal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import { ParamsProps } from '~/@types/params';

import MessageList from '~/components/global/molecules/MessageList';
import TextComposer from '~/components/global/molecules/TextComposer';
import { useContact } from '~/hooks/contacts/contact';
import { useMessages } from '~/hooks/messages/messages';
import { Container, BoxUpload } from './styles';

const Chat: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { contact, isLoading: isLoadingContact } = useContact();
  const { isLoading: isLoadingMessages, sendMessageFile } = useMessages();

  const onDrop = useCallback(
    acceptedFiles => {
      sendMessageFile(acceptedFiles[0]);
    },
    [sendMessageFile],
  );

  const { getRootProps, isDragAccept, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const { contactId } = useParams<ParamsProps>();

  useEffect(() => {
    if (!isLoadingContact && !isLoadingMessages) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isLoadingMessages, contact, contactId, isLoadingContact]);

  useEffect(() => {
    setIsLoading(true);
  }, [contactId]);

  return (
    <Container {...getRootProps()}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadingOutlined style={{ fontSize: 42 }} spin />
        </div>
      ) : (
        <>
          {isDragAccept ? (
            <BoxUpload>
              <input {...getInputProps()} />
              <Result
                icon={<InboxOutlined />}
                title={intl.get(
                  'call_center.message_list.drag_and_drop_the_file_here',
                )}
              />
            </BoxUpload>
          ) : (
            <>
              <MessageList />
            </>
          )}
        </>
      )}

      <TextComposer />
    </Container>
  );
};

export default Chat;
