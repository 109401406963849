import { UserAddOutlined, UserOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Button, Tooltip, Modal, Input, Form, Avatar, Skeleton } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { useChatContacts } from '~/hooks/whatsAppWeb/chatContacts';
import { useContacts } from '~/hooks/contacts/contacts';
import { SelectOptions } from '~/@types/fields';
import PhoneInput from '../PhoneInput';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import apiWhatsApp from '~/services/apiWhatsApp';
import { ParamsProps } from '~/@types/params';

interface NewContactProps {
  id: number;
  name: string;
  number: string;
}

interface NewContactModalProps {
  type?: 'text' | 'icon';
  isOpenAfterCreated?: boolean;
  onSetContactsList?: (selectOptions: SelectOptions[]) => void;
}

interface IContactProfile {
  id: {
    server: string;
    user: string;
    _serialized: string;
  };
  avatar?: string;
}

const NewContactModal: React.FC<NewContactModalProps> = ({
  type = 'icon',
  isOpenAfterCreated = true,
  onSetContactsList,
}) => {
  const { environmentId } = useParams<ParamsProps>();
  const { environment } = useEnvironment();
  const { refetch: refetchChatContacts } = useChatContacts();
  const { refetch: refetchContacts } = useContacts();
  const [isVisible, setIsVisible] = useState(false);

  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();

  const history = useHistory();

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setAvatar(() => undefined);

    form.resetFields();
  };

  const { mutateAsync: createNewContact, isLoading } = useMutation(
    async (contact: NewContactProps) => {
      const formattedContact: NewContactProps = {
        ...contact,
        ...(avatar && { address: avatar }),
        number: clearSpecialCharacters(contact.number),
      };

      try {
        const response = await api.post(
          `/zc/${environment?.id}/client/`,
          formattedContact,
        );
        const newContact: NewContactProps = response.data;

        if (refetchChatContacts) {
          refetchChatContacts();
        }
        if (refetchContacts) {
          refetchContacts();
        }

        toast.success(
          intl.get('contact.create.successfully_created', {
            name: newContact.name,
          }),
        );
        if (isOpenAfterCreated) {
          history.push(`/c/${environmentId}/contacts/${newContact.id}`);
        }

        if (onSetContactsList) {
          const contactsList: SelectOptions[] = [
            {
              name: newContact.name || newContact.number,
              label: newContact.name || newContact.number,
              value: newContact.id,
            },
          ];
          onSetContactsList(contactsList);
        }
        handleCloseModal();
      } catch (error) {
        toast.error(intl.get('contact.create.error_creating'));
      }
    },
  );

  const { mutateAsync: getProfile, isLoading: isLoadingProfile } = useMutation(
    async (phoneNumber: string): Promise<string | undefined> => {
      const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);

      try {
        const response = await apiWhatsApp.post<IContactProfile>(
          '/contacts/profile',
          {
            phoneNumber: formattedPhoneNumber,
          },
        );

        const { data } = response;

        return data.avatar;
      } catch (error) {
        return undefined;
      }
    },
  );

  return (
    <div>
      {type === 'icon' ? (
        <Tooltip title={intl.get('contact.create.title')}>
          <Button
            type="text"
            size="large"
            icon={<UserAddOutlined />}
            onClick={handleOpenModal}
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          size="large"
          icon={<UserAddOutlined />}
          onClick={handleOpenModal}
        >
          {intl.get('contact.create.title')}
        </Button>
      )}

      <Modal
        title={intl.get('contact.create.title')}
        visible={isVisible}
        centered
        footer={null}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={values => createNewContact(values)}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isLoadingProfile ? (
              <Skeleton.Avatar size={124} />
            ) : avatar ? (
              <Avatar size={124} src={avatar} />
            ) : (
              <Avatar size={124} icon={<UserOutlined />} />
            )}
          </div>

          <Form.Item
            label={intl.get('contact.name')}
            name="name"
            rules={[
              {
                required: true,
                message: intl.get('contact.create.name_validation'),
              },
            ]}
          >
            <Input placeholder={intl.get('contact.create.name_placeholder')} />
          </Form.Item>

          <PhoneInput
            form={form}
            name="number"
            onValidated={async value => {
              if (value) {
                const avatarUrl = await getProfile(value);
                setAvatar(() => avatarUrl);
              } else {
                setAvatar(() => undefined);
              }
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
            size="large"
          >
            {intl.get('contact.create.create_contact')}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default NewContactModal;
