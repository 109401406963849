/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react';

import { useAttendance } from '~/hooks/attendances/attendance';
import FormAttendanceViewTemp from './formAttendanceViewTemp';
import {
  formatterQuestions,
  formatterSendQuestions,
  updateQuestion,
} from './solutionTemp.js';

interface FormAttendanceViewProps {
  questions: any[];
  isChildren?: boolean;
}

const FormAttendanceView: React.FC<FormAttendanceViewProps> = ({
  questions,
}) => {
  const { updateAttendanceFormOrder } = useAttendance();
  const [questionsFormatted, setQuestionsFormatted] = useState({});

  useEffect(() => {
    setQuestionsFormatted(formatterQuestions(questions));
  }, [questions]);

  const updateAndSendField = (obj: any) => {
    let title = null;
    let newValue = null;
    Object.entries(obj).map(field => {
      title = field[0];
      newValue = field[1];
    });

    const questionUpdated = updateQuestion(questionsFormatted, title, newValue);

    const questionsFormattedToSend = formatterSendQuestions(questionUpdated);
    updateAttendanceFormOrder(questionsFormattedToSend);
  };

  return (
    <>
      <FormAttendanceViewTemp
        questions={questionsFormatted}
        updateAndSendField={updateAndSendField}
      />
    </>
  );
};

export default FormAttendanceView;
