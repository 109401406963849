import { Tag } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import NavBar from '~/components/global/organisms/NavBar';
import { WhatsAppWebProvider } from '~/hooks/whatsAppWeb/whatsAppWeb';
import { ChatContactsProvider } from '~/hooks/whatsAppWeb/chatContacts';
import { useEnvironment } from '~/hooks/environments/environment';

import { Container, Header, Content } from './styles';
import TabBar from '~/components/global/organisms/TabBar';
import ChatContactsList from '~/components/WhatsAppWeb/organisms/ChatList';
import WhatsAppWebPanel from '~/components/WhatsAppWeb/organisms/Panel';
import QRCodeModal from '~/components/global/molecules/QRCodeModal';
import NotificationPermissionAlert from '~/components/global/atoms/NotificationPermissionAlert';

const WhatsAppWeb: React.FC = () => {
  const { isLoading, totalContacts } = useEnvironment();

  return (
    <Container>
      <WhatsAppWebProvider>
        <ChatContactsProvider>
          <Header>
            <NavBar>
              {!isLoading && (
                <Tag>
                  {intl.get('whatsapp_web.list.counter', {
                    total: totalContacts,
                  })}
                </Tag>
              )}
              <QRCodeModal />
            </NavBar>
            <WhatsAppWebPanel />
            <NotificationPermissionAlert />
          </Header>
          <Content>
            <ChatContactsList />
          </Content>
        </ChatContactsProvider>
      </WhatsAppWebProvider>
      <TabBar />
    </Container>
  );
};

export default WhatsAppWeb;
