import React from 'react';
import SEO from '~/components/global/atoms/SEO';

import AppProvider from '../../hooks';

import { Layout, Content } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <AppProvider>
      <Layout>
        <SEO />
        <Content>{children}</Content>
      </Layout>
    </AppProvider>
  );
};

export default DefaultLayout;
