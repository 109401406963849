/* eslint-disable react-hooks/exhaustive-deps */
import {
  AudioOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useState } from 'react';

import MicRecorder from 'mic-recorder-to-mp3';
import { toast } from 'react-toastify';
import { useMessages } from '~/hooks/messages/messages';
import RecordingTimer from './recordingTimer';
import { Container } from './styles';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const RecoderVoiceMessage: React.FC = () => {
  const { sendMessageAudio } = useMessages();

  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const handleUploadAudio = async () => {
    setIsLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setIsLoading(false);
        setIsRecording(false);
        return;
      }

      sendMessageAudio(blob);
    } catch (err) {
      toast.error(err);
    }

    setIsRecording(false);
    setIsLoading(false);
  };

  const handleStartRecording = async () => {
    setIsLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setIsRecording(true);
      setIsLoading(false);
    } catch (err) {
      toast.error(err);
      setIsLoading(false);
    }
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setIsRecording(false);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Container>
      {isRecording ? (
        <>
          <Button
            size="large"
            type="primary"
            ghost
            shape="circle"
            onClick={() => {
              handleCancelAudio();
            }}
            danger
            icon={<CloseOutlined />}
          />
          {isLoading ? (
            <div>
              <Spin indicator={<LoadingOutlined spin />} />
            </div>
          ) : (
            <RecordingTimer />
          )}
          <Button
            size="large"
            type="primary"
            ghost
            shape="circle"
            onClick={() => {
              handleUploadAudio();
            }}
            icon={<CheckOutlined />}
          />
        </>
      ) : (
        <Button
          size="large"
          type="text"
          shape="circle"
          onClick={() => {
            handleStartRecording();
          }}
          icon={<AudioOutlined />}
        />
      )}
    </Container>
  );
};

export default RecoderVoiceMessage;
