import React, { createContext, useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { StatusProps } from '~/hooks/status/status';

export interface EnvironmentsResponseProps {
  id: number;
  count: number;
  next: string;
  previous: string;
  results: EnvironmentProps[];
}

interface EnvironmentProps {
  id: number;
  name: string;
  status: StatusProps[];
}

interface EnvironmentsContextData {
  pageSize: number;
  handleChangePageSize: (value: number) => void;
  environmentsList?: EnvironmentProps[];
  totalEnvironments: number;
  hasMore: boolean;
  moreEnvironments: (page?: number) => void;
  isLoading: boolean;
  refetch: () => void;
  isFetching: boolean;
}
const EnvironmentsContext = createContext<EnvironmentsContextData>(
  {} as EnvironmentsContextData,
);

const EnvironmentsProvider: React.FC = ({ children }) => {
  const [totalEnvironments, setTotalEnvironments] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [limit, setLimit] = useState(pageSize);
  const [hasMore, setHasMore] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { data: environmentsList, refetch, isFetching } = useQuery(
    [`environmentsList`],
    async () => {
      const response = await api.get<EnvironmentsResponseProps>('/zc/config/', {
        params: {
          offset: limit - pageSize,
          limit: pageSize,
        },
      });

      setHasMore(!!response.data.next);
      setTotalEnvironments(response.data.count);

      setIsLoading(false);
      return response.data.results;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const moreEnvironments = useCallback(
    (page?: number) => {
      if (page) {
        setLimit(page * pageSize);
      } else {
        setLimit(limitOld => limitOld + pageSize);
      }
    },
    [pageSize],
  );

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  return (
    <EnvironmentsContext.Provider
      value={{
        totalEnvironments,
        environmentsList,
        hasMore,
        moreEnvironments,
        isLoading,
        refetch,
        isFetching,
        pageSize,
        handleChangePageSize,
      }}
    >
      {children}
    </EnvironmentsContext.Provider>
  );
};

function useEnvironments(): EnvironmentsContextData {
  const context = useContext(EnvironmentsContext);

  if (!context) {
    throw new Error(
      'useEnvironments must be used within an ListEnvironmentProvider',
    );
  }

  return context;
}

export { EnvironmentsProvider, useEnvironments };
