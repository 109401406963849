import React from 'react';
import { Form, Input, Button, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { useAuth, RecoveryAccountProps } from '../../hooks/auth';
import Logo from '../../assets/logo.svg';
import { Container, Content, Background } from './styles';

const { Title } = Typography;

const AccountsRecovery: React.FC = () => {
  const { recoveryPassword } = useAuth();

  const handleSubmit = (data: RecoveryAccountProps) => {
    recoveryPassword(data);
  };
  return (
    <Container>
      <Content>
        <img src={Logo} height="80px" alt="ZapForm" />
        <Title level={4}>Recuperação de conta</Title>
        <Typography.Paragraph>
          Nós enviaremos um email com os próximos passos.
        </Typography.Paragraph>

        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Por favor insira seu email' }]}
          >
            <Input placeholder="Seu email" type="email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Recuperar
            </Button>
          </Form.Item>
          <Link to="/">Voltar</Link>
        </Form>
      </Content>
      <Background />
    </Container>
  );
};

export default AccountsRecovery;
