import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';
import { EnvironmentsResponseProps } from '~/hooks/environments/environments';
import api from '~/services/api';
import { Container } from './styles';

const PageRedirect: React.FC = () => {
  const { signOut } = useAuth();

  const history = useHistory();
  const getPrimaryConfig = async () => {
    try {
      const { data } = await api.get<EnvironmentsResponseProps>('/zc/config/', {
        params: {
          offset: 0,
          limit: 1,
        },
      });
      if (data.results.length > 0) {
        localStorage.setItem(
          '@ZapForm:environmentId',
          String(data.results[0].id),
        );
        history.push(`/c/${String(data.results[0].id)}/callcenter`);
      }
    } catch (error) {
      signOut();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getPrimaryConfig();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 48, margin: '4rem' }} spin />
        }
      />
    </Container>
  );
};

export default PageRedirect;
