/* eslint-disable react/no-array-index-key */
import intl from 'react-intl-universal';
import { CommentOutlined } from '@ant-design/icons';
import { Timeline, Tag, Typography, Card, Button, Input, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';

import BaseField from '~/components/global/atoms/BaseField';

import { useAttendance } from '~/hooks/attendances/attendance';

const AttendanceLog: React.FC = () => {
  const { attendance, isLoading, sendNote } = useAttendance();
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);

  const handleOpenNoteModal = () => {
    setIsNoteModalVisible(true);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalVisible(false);
  };

  const handleSendNote = async (note: string) => {
    try {
      await sendNote(note);
      handleCloseNoteModal();
    } catch (error) {
      setIsNoteModalVisible(true);
    }
  };

  if (isLoading || !attendance?.status_history) {
    return (
      <div>
        <Card loading bordered={false} />
      </div>
    );
  }
  return (
    <div>
      <Modal
        visible={isNoteModalVisible}
        centered
        title={intl.get('log.new_note')}
        onOk={() => handleOpenNoteModal()}
        onCancel={() => handleCloseNoteModal()}
        footer={null}
      >
        <Form onFinish={values => handleSendNote(values.note)}>
          <Form.Item name="note">
            <Input.TextArea
              placeholder={intl.get('log.describe_your_observation_here')}
              size="large"
            />
          </Form.Item>
          <Button htmlType="submit" size="large" type="primary" block>
            {intl.get('log.send_note')}
          </Button>
        </Form>
      </Modal>
      <Button
        size="large"
        block
        shape="round"
        icon={<CommentOutlined />}
        onClick={() => handleOpenNoteModal()}
      >
        {intl.get('log.new_note')}
      </Button>
      <Timeline style={{ marginTop: '1rem' }}>
        {attendance.status_history.map((log, logIndex) => {
          if (log.event_data.change) {
            return (
              <Timeline.Item key={logIndex} color="red">
                <Tag>{log.status.status}</Tag>
                <br />
                <small>{log.time_createdFormatted}</small>

                <BaseField
                  small
                  label={intl.get('log.modified_by')}
                  value={log.event_data.user}
                />

                {log.event_data.message && (
                  <BaseField
                    small
                    label={intl.get('log.note')}
                    value={log.event_data.message}
                  />
                )}

                <BaseField small label={intl.get('log.modifications')}>
                  <ul style={{ paddingLeft: '1rem' }}>
                    {log.event_data.change.order &&
                      Object.entries(log.event_data.change.order).map(
                        (question, questionIndex) => (
                          <li key={questionIndex}>
                            {question[0]}:{' '}
                            <Typography.Text delete>
                              {typeof question[1].old === 'object'
                                ? JSON.stringify(question[1].old)
                                : String(question[1].old)}
                            </Typography.Text>
                            {' > '}
                            <Typography.Text>
                              {typeof question[1].new === 'object'
                                ? JSON.stringify(question[1].new)
                                : String(question[1].new)}
                            </Typography.Text>
                          </li>
                        ),
                      )}
                    {log.event_data.change.location && (
                      <li key={log.event_data.change.location.new}>
                        {intl.get('responsible.title')}:{' '}
                        <Typography.Text delete>
                          {String(log.event_data.change.location.old)}
                        </Typography.Text>
                        {' > '}
                        <Typography.Text>
                          {String(log.event_data.change.location.new)}
                        </Typography.Text>
                      </li>
                    )}
                  </ul>
                </BaseField>
              </Timeline.Item>
            );
          }

          if (log.event_data.source === 'creation') {
            return (
              <Timeline.Item key={logIndex} color="green">
                <Tag>{log.status.status}</Tag>
                <Tag color="green">{intl.get('log.creation')}</Tag>
                <br />

                {log.event_data.message && (
                  <BaseField
                    small
                    label={intl.get('log.note')}
                    value={log.event_data.message}
                  />
                )}
                <>{log.time_createdFormatted}</>
              </Timeline.Item>
            );
          }
          return (
            <Timeline.Item
              key={logIndex}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Tag>{log.status.status}</Tag>

              <br />
              <>{log.time_createdFormatted}</>

              <BaseField
                small
                label={intl.get('log.modified_by')}
                value={log.event_data.user}
              />

              {log.event_data.message && (
                <BaseField
                  small
                  label={intl.get('log.note')}
                  value={log.event_data.message}
                />
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default AttendanceLog;
