import { ArrowUpOutlined, NotificationOutlined } from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useUnreadMessages } from '~/hooks/messages/unreadMessages';

const NotificationPermissionAlert: React.FC = () => {
  const config = {
    title: intl.get('alert_allow_notifications.title'),
    icon: <ArrowUpOutlined rotate={-45} />,
    content: (
      <div>
        <p>{intl.getHTML('alert_allow_notifications.description')}</p>
      </div>
    ),
    okText: intl.get('alert_allow_notifications.ok'),
  };

  const {
    requestPermissionNotification,
    notificationsIsGuaranteed,
  } = useUnreadMessages();

  if (!notificationsIsGuaranteed) {
    return (
      <Alert
        message={intl.get('allow_notifications.title')}
        description={
          <Button size="small" type="link">
            {intl.get('allow_notifications.button_active')}
          </Button>
        }
        type="info"
        icon={<NotificationOutlined />}
        showIcon
        onClick={() => {
          Modal.info(config);
          requestPermissionNotification();
        }}
        style={{ cursor: 'pointer' }}
      />
    );
  }
  return <></>;
};

export default NotificationPermissionAlert;
