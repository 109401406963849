import React, { createContext, useContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

export interface BotProps {
  enabled: boolean;
}

interface BotContextData {
  isBotEnabled?: boolean;
  isLoading: boolean;
  isBotStatusInitially?: boolean;
  updateBotStatus: (value: boolean) => void;
  refetch: () => void;
  handleSetBotStatusInitially: (value: boolean) => void;
}

export const BotContext = createContext<BotContextData>({} as BotContextData);

interface BotProviderProps {
  contactId: number;
}

const BotProvider: React.FC<BotProviderProps> = ({ children, contactId }) => {
  const { environment } = useEnvironment();

  const [isBotEnabled, setIsBotEnabled] = useState<boolean | undefined>(
    undefined,
  );

  const [isBotStatusInitially, setIsBotStatusInitially] = useState<
    boolean | undefined
  >(undefined);

  const handleSetBotStatusInitially = (value: boolean) => {
    setIsBotStatusInitially(value);
  };

  const handlePersistBot = () => {
    if (isBotStatusInitially) {
      updateBotStatus(true);
    } else {
      updateBotStatus(false);
    }
  };

  window.onbeforeunload = () => {
    handlePersistBot();
  };

  useEffect(() => {
    async function getInicialStatus() {
      if (!environment || !contactId) {
        return;
      }
      const response = await api.get(
        `/zc/${environment.id}/client/${contactId}/bot/`,
      );
      const { data } = response;

      setIsBotStatusInitially(data.enabled);
    }

    getInicialStatus();
  }, [environment, contactId]);

  const { isLoading, refetch } = useQuery(
    [`bot${contactId}`, environment?.id, contactId],
    async () => {
      if (!environment || !contactId) {
        return;
      }

      const response = await api.get(
        `/zc/${environment.id}/client/${contactId}/bot/`,
      );
      const { data } = response;

      setIsBotEnabled(data.enabled);
    },
    {
      cacheTime: 0,
      refetchInterval: 10000,
      refetchOnMount: true,
    },
  );

  const updateBotStatus = async (value: boolean) => {
    try {
      await api.put(`/zc/${environment?.id}/client/${contactId}/bot/`, {
        enabled: value,
      });

      setIsBotEnabled(value);
    } catch (err) {
      toast.error(intl.get('bot.error_update'));
    }
  };

  return (
    <BotContext.Provider
      value={{
        isBotEnabled,
        isLoading,
        updateBotStatus,
        refetch,
        isBotStatusInitially,
        handleSetBotStatusInitially,
      }}
    >
      {children}
    </BotContext.Provider>
  );
};
function useBot(): BotContextData {
  const context = useContext(BotContext);

  if (!context) {
    throw new Error('useBot must be used within an BotProvider');
  }

  return context;
}

export { BotProvider, useBot };
