import React from 'react';

import { AttendanceProvider } from '~/hooks/attendances/attendance';

import AttendanceComponent from '~/components/CallCenter/organisms/Attendance';

const Attendance: React.FC = () => {
  return (
    <AttendanceProvider>
      <AttendanceComponent />
    </AttendanceProvider>
  );
};

export default Attendance;
