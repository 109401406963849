import { Divider, Skeleton } from 'antd';
import React from 'react';

import { Container, Menu } from './styles';

import { useContact } from '~/hooks/contacts/contact';
import DrawerContact from '~/components/global/molecules/DrawerContact';

import ChangeStatusChatBot from '~/components/global/atoms/ChangeStatusChatBot';

const ChatTitleBar: React.FC = () => {
  const { contact, isLoading } = useContact();

  if (isLoading || !contact) {
    return (
      <>
        <div>
          <Skeleton.Input active style={{ width: 150 }} />
        </div>
        <div>
          <Skeleton.Input active style={{ width: 200 }} />
        </div>
      </>
    );
  }

  return (
    <Container>
      <div />
      <Menu>
        <DrawerContact />

        <Divider type="vertical" />
        <ChangeStatusChatBot />
      </Menu>
    </Container>
  );
};

export default ChatTitleBar;
