import React from 'react';

import ReactPlayer from 'react-player/lazy';

interface AudioProps {
  url: string;
}
const Audio: React.FC<AudioProps> = ({ url }) => {
  return (
    <div>
      <ReactPlayer controls width="100%" height="60px" url={url} />
    </div>
  );
};

export default Audio;
