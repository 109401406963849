/* eslint-disable prettier/prettier */
import React, { memo } from 'react';
import { Avatar, Tag, Divider } from 'antd';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { AttendancesItemProps } from '~/@types/attendances';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { Container } from './styles';
import UnreadMessages from '~/components/global/atoms/UnreadMessages';

import BaseField from '~/components/global/atoms/BaseField';
import { ParamsProps } from '~/@types/params';

interface ChatItemProps {
  data: AttendancesItemProps;
}

const ChatItem: React.FC<ChatItemProps> = ({ data }) => {
  const history = useHistory();

  const { environmentId } = useParams<ParamsProps>();

  const { settings } = useCallCenter();

  return (
    <Container
      key={data.id}
      onClick={() => history.push(`/c/${environmentId}/callcenter/${data.id}`)}
    >
      <Text type="secondary">
        <b>#{data.id}</b>
      </Text>
      <div className="title">
        <div style={{ display: 'flex' }}>
          {settings.title?.includes('contact.avatar') && (
            <>
              {data.contact.address === '' ? (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: stringToHslColor(data.contact.name),
                  }}
                >
                  {createLetterAvatar(data.contact.name)}
                </Avatar>
              ) : (
                <Avatar size="large" src={data.contact.address} />
              )}
            </>
          )}
          <div style={{ marginLeft: '0.5rem' }}>
            <div style={{ width: '160px' }}>
              <Text>
                {settings.title?.includes('contact.id_name')
                  ? data.contact.id_name
                  : data.contact.name}
              </Text>
              <br />
              <Text type="secondary">
                {settings.title?.includes('contact.number') &&
                  data.contact.number}
              </Text>
            </div>
          </div>
        </div>

        <div style={{ width: '40px' }}>
          <UnreadMessages contactId={data.contact.id} />
        </div>
      </div>

      <div>
        <Tag className="tag">
          {settings.title?.includes('status.code_status')
            ? data.status.code_status
            : data.status.status}
        </Tag>
      </div>

      <div className="sub-titles">
        {/* CONTACT */}


        {/* RESPONSIBLE */}
        {settings.subTitles?.includes('responsible.name') &&
          data.responsible.name && (
            <BaseField
              small
              label={intl.get('attendance.responsible.name')}
              value={data.responsible.name}
            />
          )}

        {settings.subTitles?.includes('responsible.address') &&
          data.responsible.address && (
            <BaseField
              small
              label={intl.get('attendance.responsible.address')}
              value={data.responsible.address}
            />
          )}

        {/* ATTENDANCE */}
        {settings.subTitles?.includes('attendance.order_summary') &&
          data.order_summary && (
            <BaseField
              small
              label={intl.get('attendance.order_summary')}
              value={data.order_summary}
            />
          )}
        {settings.subTitles?.includes('attendance.time_created') &&
          data.time_createdFormatted && (
            <BaseField
              small
              label={intl.get('attendance.creation_date')}
              value={data.time_createdFormatted}
            />
          )}
        {settings.subTitles?.includes('attendance.time_createdThere') &&
          data.time_createdThere && (
            <BaseField
              small
              label={intl.get('attendance.creation_date_there')}
              value={data.time_createdThere}
            />
          )}
        {settings.subTitles?.includes('attendance.time_status') &&
          data.time_statusFormatted && (
            <BaseField
              small
              label={intl.get('attendance.date_of_status_change')}
              value={data.time_statusFormatted}
            />
          )}
        {settings.subTitles?.includes('attendance.time_deadline') &&
          data.time_deadlineFormatted && (
            <BaseField
              small
              label={intl.get('attendance.deadline')}
              value={data.time_deadlineFormatted}
            />
          )}

        {settings.subTitles?.includes('attendance.time_timeout') &&
          data.time_howIsTimeout && (
            <BaseField
              small
              label={intl.get('attendance.deadline_expiration')}
              value={data.time_timeoutFormatted}
              style={{
                color:
                  data.time_howIsTimeout === 'timeout'
                    ? 'red'
                    : data.time_howIsTimeout === 'insideTime'
                      ? '#faad14'
                      : 'none',
              }}
            />
          )}
      </div>
      <Divider style={{ margin: '0.5rem 0 0 0' }} />
    </Container>
  );
};

export default memo(ChatItem, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
