import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import intl from 'react-intl-universal';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
import isEmpty from '~/util/isEmpty';
import { FiltersProps } from '~/@types/attendances';
import { SelectOptions } from '~/@types/fields';

const Filters: React.FC = () => {
  const [form] = Form.useForm();

  const [isVisible, setIsVisible] = useState(false);

  const { statusList } = useStatus();
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const { filters, setNewFilters, cleanFilters } = useCallCenter();

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isEmpty(filters)) {
      form.resetFields();
    }
    form.setFieldsValue({
      ...filters,
      ...(filters.timeCreated && {
        timeCreated: [
          moment(filters.timeCreated[0]),
          moment(filters.timeCreated[1]),
        ],
      }),
      ...(filters.timeLastUpdated && {
        timeLastUpdated: [
          moment(filters.timeLastUpdated[0]),
          moment(filters.timeLastUpdated[1]),
        ],
      }),
      ...(filters.timeStatus && {
        timeStatus: [
          moment(filters.timeStatus[0]),
          moment(filters.timeStatus[1]),
        ],
      }),
    });
  }, [filters, form]);

  if (!statusList || isLoadingResponsiblesList || !responsiblesList) {
    return <Button type="text" loading />;
  }

  const onFieldsChange = async (allValues: FiltersProps) => {
    const newValues = {
      ...allValues,
      ...(allValues.timeCreated && {
        timeCreated: [
          moment(allValues.timeCreated[0]).startOf('day').toISOString(),
          moment(allValues.timeCreated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(allValues.timeLastUpdated && {
        timeLastUpdated: [
          moment(allValues.timeLastUpdated[0]).startOf('day').toISOString(),
          moment(allValues.timeLastUpdated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(allValues.timeStatus && {
        timeStatus: [
          moment(allValues.timeStatus[0]).startOf('day').toISOString(),
          moment(allValues.timeStatus[1]).endOf('day').toISOString(),
        ],
      }),
    };

    setNewFilters(newValues);
  };

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  return (
    <div>
      {isEmpty(filters) ? (
        <Button type="text" icon={<FilterOutlined />} onClick={showDrawer} />
      ) : (
        <Button
          type="primary"
          danger
          icon={<FilterFilled />}
          onClick={showDrawer}
        />
      )}
      <Drawer
        title={intl.get('call_center.filters.title')}
        placement="top"
        height="100%"
        closable
        onClose={onClose}
        visible={isVisible}
        getContainer={false}
        footer={
          <Button type="primary" onClick={onClose} block>
            {intl.get('buttons.save_filters')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) =>
            onFieldsChange(allValues)
          }
        >
          <Form.Item
            label={intl.get('attendance.filters.status')}
            name="statusCode"
          >
            <Select
              placeholder={intl.get('attendance.filters.status_placeholder')}
            >
              {statusList.map(state => (
                <Select.Option key={state.code} value={`${state.code}`}>
                  {state.code} - {state.status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.attendance_id')}
            name="attendanceId"
          >
            <Input
              type="number"
              placeholder={intl.get(
                'attendance.filters.attendance_id_placeholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.search_in_the_attendance')}
            name="attendance"
          >
            <Input
              placeholder={intl.get(
                'attendance.filters.search_in_the_attendance_placeholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.contact_information')}
            name="contact"
          >
            <Input
              placeholder={intl.get(
                'attendance.filters.contact_information_placeholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.responsible_for_the_service')}
            name="responsible"
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={intl.get(
                'attendance.filters.responsible_for_the_service_placeholder',
              )}
              filterSort={(optionA, optionB) =>
                optionA?.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLowerCase())
              }
              options={formattedResponsiblesList}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.created_between')}
            name="timeCreated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              ranges={{
                [intl.get('date.today')]: [
                  moment().startOf('day'),
                  moment().endOf('day'),
                ],
                [intl.get('date.this_week')]: [
                  moment().startOf('week'),
                  moment().endOf('week'),
                ],
                [intl.get('date.this_month')]: [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.latest_changes_between')}
            name="timeLastUpdated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              ranges={{
                [intl.get('date.today')]: [
                  moment().startOf('day'),
                  moment().endOf('day'),
                ],
                [intl.get('date.this_week')]: [
                  moment().startOf('week'),
                  moment().endOf('week'),
                ],
                [intl.get('date.this_month')]: [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.status_changes_between')}
            name="timeStatus"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              ranges={{
                [intl.get('date.today')]: [
                  moment().startOf('day'),
                  moment().endOf('day'),
                ],
                [intl.get('date.this_week')]: [
                  moment().startOf('week'),
                  moment().endOf('week'),
                ],
                [intl.get('date.this_month')]: [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
        </Form>

        <Button
          type="ghost"
          onClick={() => {
            cleanFilters();
          }}
          block
        >
          {intl.get('buttons.clean_filters')}
        </Button>
      </Drawer>
    </div>
  );
};

export default Filters;
