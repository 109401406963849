import React from 'react';
import intl from 'react-intl-universal';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from 'antd/lib/typography/Text';
import { useChatAttendances } from '~/hooks/callCenter/chatAttendances';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { Container } from './styles';
import ChatItem from '../../molecules/ChatItem';
import isEmpty from '~/util/isEmpty';

const ChatList: React.FC = () => {
  const {
    attendancesList,
    hasMore,
    moreAttendances,
    totalAttendances,
    isLoading,
  } = useChatAttendances();

  const { filters, quickSearch } = useCallCenter();

  if (!isLoading && totalAttendances === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem',
        }}
      >
        <Text type="secondary">{intl.get('call_center.no_attendance')}</Text>
      </div>
    );
  }

  return (
    <Container id="scrollableDivListAttendances">
      {(!isEmpty(filters) || quickSearch !== '') && (
        <div style={{ margin: '0.5rem auto' }}>
          <Text type="secondary">
            {intl.get('call_center.list.counter', { total: totalAttendances })}
          </Text>
        </div>
      )}

      {isLoading && totalAttendances === 0 ? (
        <>
          <div style={{ padding: '0.5rem' }}>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </div>
          <div style={{ padding: '0.5rem' }}>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </div>
          <div style={{ padding: '0.5rem' }}>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </div>
        </>
      ) : (
        <InfiniteScroll
          dataLength={attendancesList.length}
          next={moreAttendances}
          hasMore={hasMore}
          loader={
            <div
              style={{
                margin: '1rem auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
          }
          scrollableTarget="scrollableDivListAttendances"
          style={{ overflow: 'none' }}
        >
          {attendancesList.map(attendance => (
            <ChatItem key={attendance.id} data={attendance} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default ChatList;
