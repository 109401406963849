import { Button, Card } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import ChangeLocale from '~/components/global/atoms/ChangeLocale';
import SelectEnvironment from '~/components/global/molecules/SelectEnvironment';

import TabBar from '~/components/global/organisms/TabBar';
import { useUser } from '~/hooks/user';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { Container, Content } from './styles';
import { useAuth } from '~/hooks/auth';
import { ParamsProps } from '~/@types/params';

const More: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const { signOut } = useAuth();
  const { user, isLoading } = useUser();

  if (isLoading || !user) {
    return (
      <Container>
        <Content>
          <Card loading />
        </Content>
      </Container>
    );
  }
  return (
    <Container>
      <Content>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0.5rem',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <Avatar
            size="large"
            style={{
              backgroundColor: stringToHslColor(user.name),
            }}
          >
            {createLetterAvatar(user.name)}
          </Avatar>
          <div>{user.name}</div>
        </div>
        <Card
          bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          <SelectEnvironment />
          <ChangeLocale />
        </Card>
        <Card
          bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          <Link to={`/c/${environmentId}/account`}>
            <Button block>{intl.get('profile.title')}</Button>
          </Link>

          <Button onClick={() => signOut()} danger block>
            {intl.get('buttons.exit')}
          </Button>
        </Card>
      </Content>

      <TabBar />
    </Container>
  );
};

export default More;
