import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useAuth } from './auth';

export interface UserProps {
  username: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  apps: [
    | 'zapform'
    | 'zapform.change_form'
    | 'inspira'
    | 'zapdelivery'
    | 'zapchamado'
    | 'zapchamado.generate_qr'
    | 'zapchamado.chat'
    | 'zapchamado.report',
  ];
}

export interface UserPasswordProps {
  new_password1: string;
  new_password2: string;
}

interface UserContextData {
  user?: UserProps;
  isLoading: boolean;
  refetch: () => void;
  updateUser: (data: UserProps) => void;
  changePassword: (data: UserPasswordProps) => void;
}
const UserContext = createContext<UserContextData>({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const { token } = useAuth();

  const { data: user, isLoading, refetch } = useQuery(
    [`user`, token],
    async () => {
      if (!token) {
        return undefined;
      }
      const response = await api.get('auth/user/');

      const { data } = response;

      data.name = `${data.first_name} ${data.last_name}`;

      return data;
    },
  );

  const updateUser = async (data: UserProps) => {
    try {
      await api.put('auth/user/', {
        ...user,
        ...data,
      });
      toast.success('Dados atualizado com sucesso');
      refetch();
    } catch (error) {
      toast.error('Não foi possivel atualizar seus dados');
    }
  };

  const changePassword = async (data: UserPasswordProps) => {
    try {
      await api.post('auth/password/change/', data);
      toast.success('Senha alterada com sucesso');
      refetch();
    } catch (error) {
      toast.error('Não foi possivel atualizar sua senha');
    }
  };

  return (
    <UserContext.Provider
      value={{ user, isLoading, refetch, updateUser, changePassword }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
