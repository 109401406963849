import {
  CameraOutlined,
  CameraTwoTone,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Button, Result } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BarcodeScanner from 'react-webcam-barcode-scanner';
import intl from 'react-intl-universal';
import { ParamsProps } from '~/@types/params';

// import { Container } from './styles';

interface QRCode {
  type: 'attendance' | 'contact';
  value: number;
}

const QRCodeModal: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleQRCodeRead = (value: string) => {
    closeModal();
    const qrCode: QRCode = JSON.parse(value);

    if (qrCode.type === 'attendance') {
      history.push(`/c/${environmentId}/callcenter/${qrCode.value}`);
    }
    if (qrCode.type === 'contact') {
      history.push(`/c/${environmentId}/whatsappweb/${qrCode.value}`);
    }
  };
  // navigator.getUserMedia({ video: true }).then(() => {
  //   console.log('com permissão');
  // });

  const [isCameraEnabled, setIsCameraEnabled] = useState<
    'granted' | 'prompt' | 'denied' | undefined
  >(undefined);
  const permissionName = 'camera' as PermissionName;
  navigator.permissions.query({ name: permissionName }).then(
    ({ state }) => {
      if (state === 'granted') {
        setIsCameraEnabled('granted');
      } else if (state === 'prompt') {
        setIsCameraEnabled('prompt');
      } else {
        setIsCameraEnabled('denied');
      }
    },
    () => setIsCameraEnabled('denied'),
  );

  const checkCameraEnabled = () => {
    if (isCameraEnabled === 'prompt') {
      return (
        <Result
          icon={<CameraOutlined />}
          subTitle={intl.getHTML(
            'call_center.view_on_cell_phone.alert_allow_camera.prompt.description',
          )}
          extra={
            <Button
              type="primary"
              onClick={() => {
                history.go(0);
              }}
            >
              {intl.getHTML(
                'call_center.view_on_cell_phone.alert_allow_camera.prompt.ok',
              )}
            </Button>
          }
        />
      );
    }
    if (isCameraEnabled === 'denied') {
      return (
        <Result
          icon={<CameraTwoTone twoToneColor="red" />}
          title={intl.getHTML(
            'call_center.view_on_cell_phone.alert_allow_camera.denied.title',
          )}
          subTitle={intl.getHTML(
            'call_center.view_on_cell_phone.alert_allow_camera.denied.description',
          )}
        />
      );
    }
    return <></>;
  };
  return (
    <>
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<QrcodeOutlined />}
        onClick={() => {
          showModal();
        }}
      />

      <Modal
        title={intl.get('call_center.qrcode_reader.title')}
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        centered
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          {checkCameraEnabled()}

          <BarcodeScanner
            width={340}
            height={isCameraEnabled === 'granted' ? 440 : 0}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onUpdate={(err, result: any) => {
              if (result) {
                handleQRCodeRead(result.text);
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default QRCodeModal;
