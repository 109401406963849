import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useAuth } from '../../hooks/auth';
import { Container, Content } from './styles';
import Logo from '../../assets/logo.svg';
import ChangeLocale from '~/components/global/atoms/ChangeLocale';

const { Title } = Typography;

type SignInFormData = {
  username: string;
  password: string;
};

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      setIsLoading(true);
      try {
        await signIn({
          username: data.username,
          password: data.password,
        });
        history.push('/redirect');
      } catch (error) {
        toast.error(intl.get('auth.sign_in.error_sign_in'));
      }

      setIsLoading(false);
    },
    [signIn, history],
  );

  return (
    <Container>
      <Content>
        <img src={Logo} height="80px" alt="ZapForm" />
        <Title level={4}>{intl.get('auth.sign_in.description')}</Title>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item>
            <ChangeLocale />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: intl.get('auth.sign_in.username_required'),
              },
            ]}
          >
            <Input placeholder={intl.get('auth.sign_in.username')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: intl.get('auth.sign_in.password_required'),
              },
            ]}
          >
            <Input.Password placeholder={intl.get('auth.sign_in.password')} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              {intl.get('buttons.to_enter')}
            </Button>
          </Form.Item>
          {/* <Link to="/accounts/recovery">Esqueci minha senha</Link> */}
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
