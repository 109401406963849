import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useContact } from '~/hooks/contacts/contact';
// import { Container } from './styles';

const DeleteContactButton: React.FC = () => {
  const { deleteContact } = useContact();

  const handleDeleteContact = () => {
    deleteContact();
  };

  return (
    <div>
      <Popconfirm
        title={intl.get('contact.delete.title')}
        onConfirm={handleDeleteContact}
        okText={intl.get('contact.delete.yes')}
        cancelText={intl.get('contact.delete.no')}
      >
        <Button
          size="large"
          shape="round"
          danger
          block
          icon={<DeleteOutlined />}
        >
          {intl.get('contact.delete.title')}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default DeleteContactButton;
