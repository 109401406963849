import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import intl from 'react-intl-universal';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import MessageList from '~/components/global/molecules/MessageList';
import TextComposer from '~/components/global/molecules/TextComposer';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useMessages } from '~/hooks/messages/messages';
import { Container, BoxUpload } from './styles';

const Chat: React.FC = () => {
  const { attendance, isLoading: isLoadingAttendance } = useAttendance();

  const { sendMessageFile } = useMessages();

  const onDrop = useCallback(
    acceptedFiles => {
      sendMessageFile(acceptedFiles[0]);
    },
    [sendMessageFile],
  );

  const { getRootProps, isDragAccept, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  if (isLoadingAttendance || !attendance) {
    return (
      <Container {...getRootProps()}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadingOutlined style={{ fontSize: 42 }} spin />
        </div>
      </Container>
    );
  }

  return (
    <Container {...getRootProps()}>
      {isDragAccept ? (
        <BoxUpload>
          <input {...getInputProps()} />
          <Result
            icon={<InboxOutlined />}
            title={intl.get(
              'call_center.message_list.drag_and_drop_the_file_here',
            )}
          />
        </BoxUpload>
      ) : (
        <>
          <MessageList />
        </>
      )}

      <TextComposer />
    </Container>
  );
};

export default Chat;
