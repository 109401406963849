import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useAttendance } from '~/hooks/attendances/attendance';

import FormAttendanceView from '~/components/global/molecules/FormAttendanceView';

// import { Container } from './styles';

const DrawerAnsweredForm: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  const [isVisible, setIsVisible] = useState(false);

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  if (isLoading || !attendance) {
    return (
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<UnorderedListOutlined />}
        loading
      />
    );
  }

  return (
    <>
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<UnorderedListOutlined />}
        onClick={() => {
          showDrawer();
        }}
      />

      <Drawer
        title={intl.get('call_center.title_bar.answered_form')}
        placement="bottom"
        height="100%"
        onClose={onClose}
        visible={isVisible}
      >
        <FormAttendanceView questions={attendance.order} />
      </Drawer>
    </>
  );
};

export default DrawerAnsweredForm;
