import React, { memo } from 'react';
import { Avatar, Divider } from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { ContactProps } from '~/hooks/whatsAppWeb/chatContacts';
import { Container } from './styles';
import UnreadMessages from '~/components/global/atoms/UnreadMessages';
import { useWhatsAppWeb } from '~/hooks/whatsAppWeb/whatsAppWeb';
import { ParamsProps } from '~/@types/params';

interface ContactChatItemProps {
  data: ContactProps;
}

const ContactChatItem: React.FC<ContactChatItemProps> = ({ data }) => {
  const { environmentId } = useParams<ParamsProps>();
  const history = useHistory();

  const { settings } = useWhatsAppWeb();

  return (
    <Container
      key={data.id}
      onClick={() => history.push(`/c/${environmentId}/whatsappweb/${data.id}`)}
    >
      <div className="title">
        <div style={{ display: 'flex' }}>
          {settings.title?.includes('contact.avatar') && (
            <>
              {data.address === '' ? (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: stringToHslColor(data.name),
                  }}
                >
                  {createLetterAvatar(data.name)}
                </Avatar>
              ) : (
                <Avatar size="large" src={data.address} />
              )}
            </>
          )}
          <div style={{ marginLeft: '0.5rem' }}>
            <div style={{ width: '160px' }}>
              <Text>
                {settings.title?.includes('contact.id_name')
                  ? data.id_name
                  : data.name}
              </Text>
              <br />
              <Text type="secondary">
                {settings.title?.includes('contact.number') && data.number}
              </Text>
            </div>
          </div>
        </div>

        <div style={{ width: '40px' }}>
          <UnreadMessages contactId={data.id} />
        </div>
      </div>

      <div className="sub-titles">{/* CONTACT */}</div>
      <Divider style={{ margin: '0.5rem 0 0 0' }} />
    </Container>
  );
};

export default memo(ContactChatItem, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
