import React from 'react';

import Filters from '../../molecules/Filters';
import Settings from '../../molecules/Settings';

import { Container } from './styles';

const Panel: React.FC = () => {
  return (
    <Container>
      <Filters />
      <Settings />
    </Container>
  );
};

export default Panel;
