/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, Tooltip } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import BaseField from '~/components/global/atoms/BaseField';
import DeleteContactButton from '~/components/global/atoms/DeleteContactButton';
import { useContact } from '~/hooks/contacts/contact';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';
// import { Container } from './styles';

const DrawerContact: React.FC = () => {
  const { contact, isLoading, updateContactField, updateAvatar } = useContact();

  const [isVisible, setIsVisible] = useState(false);

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  if (isLoading || !contact) {
    return (
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<UserOutlined />}
        loading
      />
    );
  }

  return (
    <>
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<UserOutlined />}
        onClick={() => {
          showDrawer();
        }}
      />

      <Drawer
        title={intl.get('call_center.title_bar.contact_information')}
        placement="bottom"
        height="100%"
        onClose={onClose}
        visible={isVisible}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="Atualizar foto de perfil" placement="bottom">
            <a
              href="#"
              onClick={() => {
                updateAvatar();
              }}
            >
              {contact.address === '' ? (
                <Avatar
                  size={124}
                  style={{
                    backgroundColor: stringToHslColor(contact.name),
                  }}
                >
                  {createLetterAvatar(contact.name)}
                </Avatar>
              ) : (
                <Avatar size={124} src={contact.address} />
              )}
            </a>
          </Tooltip>
        </div>

        <BaseField label="ID" copyable value={contact.id} />

        <BaseField
          label={intl.get('contact.name')}
          name="name"
          value={contact.name}
          editable
          onChange={field => {
            updateContactField(field);
          }}
        />

        <BaseField
          type="phone"
          label={intl.get('contact.number')}
          name="number"
          value={contact.number}
          editable
          copyable
          onChange={field => {
            updateContactField(field);
          }}
        />

        <DeleteContactButton />
      </Drawer>
    </>
  );
};

export default DrawerContact;
