import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

:root {
  --primary-color: #009a63;
  --secondary-color: #ff6600;

  --success-color: #52c41a;
  --warning-color: #faad14;
  --error-color: #f5222d;

  --background: #fafbfc;

  --blue-color: #1890ff;

  --gray-color: #f0f2f5;
  --gray-3: #f5f5f5;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-9: #434343;

  --font-size-medium: 12px;
  --font-size-small: 10px;

  --radius: 8px;

  --border: 2px solid #f0f2f5;
  --margin: 8px;
  --global-header-height: 55px;
}

  * {
    box-sizing: border-box  !important;
    outline: 0  !important;
  }
  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased ;
  }
  body, input, button {
    font-family: 'Roboto', serif !important;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500 !important;
  }

  button {
    cursor: pointer;
    line-height: 0;
  }

  ::-webkit-scrollbar{
    height:8px;
    width:8px;
  }

  ::-webkit-scrollbar-thumb
  {
    background: var(--gray-6);
    border-radius:10px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background:var(--gray-7);

  }

  .PhoneInput {
    display: flex;
    align-items: center;

    input {
      box-sizing: border-box;
      margin: 0;
      min-height: 40px;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      transition: all 0.3s;

      ::placeholder {
        color: #bfbfbf;
      }
    }

    .PhoneInputCountry {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      margin-right: 0.35em;
    }

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountryIcon {
      width: calc(1.5em * 1.5);
      height: 1.5em;
      line-height: 0;
    }

    .PhoneInputCountrySelectArrow {
      display: block;
      content: '';
      width: 0.3em;

      height: 0.3em;

      margin-left: 0.35em;

      border-style: solid;
      border-color: inherit;

      border-top-width: 0;
      border-bottom-width: 1px;

      border-left-width: 0;
      border-right-width: 1px;

      -webkit-transform: rotate(45deg);

      transform: rotate(45deg);

      opacity: 0.45;
    }

    option {
      font-weight: normal;
      display: block;
      white-space: nowrap;
      min-height: 1.2em;
      padding: 0px 2px 1px;
    }
  }

`;
