import { Image } from 'antd';
import React, { ReactElement } from 'react';
import ReactPlayer from 'react-player';
import { MdDone, MdDoneAll } from 'react-icons/md';
import Paragraph from 'antd/lib/typography/Paragraph';
import ReactHtmlParser from 'react-html-parser';
import intl from 'react-intl-universal';
import Document from '~/components/global/atoms/Document';
import { MessageProps as IMessageProps } from '~/hooks/messages/messages';
import * as styles from './styles';
import Address from '~/components/global/atoms/Address';
import Linkify from '../Linkify';

interface MessageProps {
  data: IMessageProps;
}

const Message: React.FC<MessageProps> = ({ data }) => {
  const message = data;

  const formatterText = (text: string): ReactElement => {
    let textResult = '';

    try {
      textResult = text.replace(/\*[\w\W]+\*/g, match => {
        const textMatch = match.replace(/\*/g, '');
        return `<b>${textMatch}</b>`;
      });

      textResult = textResult.replace(/_[\w\W]+_/g, match => {
        const textMatch = match.replace(/_/g, '');
        return `<i>${textMatch}</i>`;
      });

      textResult = textResult.replace(/```[\w\W]+```/g, match => {
        const textMatch = match.replace(/```/g, '');
        return `<div class="reply">${textMatch}</div>`;
      });

      textResult = textResult.replace(/(\r\n|\n|\r)/gm, '<br />');
    } catch (error) {
      textResult = intl.get('message_list.message.error_displaying_message');
    }

    return <Linkify>{ReactHtmlParser(textResult)}</Linkify>;
  };
  return (
    <div>
      <styles.Wrapper dir={message.msg_dir === 'i' ? 'received' : 'sent'}>
        <styles.Container type={message.msg_type}>
          {
            {
              ch: (
                <Paragraph> {formatterText(message.msg_data.text)}</Paragraph>
              ),
              im: <Image src={message.msg_data.url} alt="Imagem" />,
              au: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              pt: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              do: <Document url={message.msg_data.url} />,
              lo: (
                <Address
                  lat={message.msg_data.lat}
                  lng={message.msg_data.lng}
                />
              ),
              vi: (
                <ReactPlayer controls width="100%" url={message.msg_data.url} />
              ),
            }[message.msg_type]
          }
        </styles.Container>
        <styles.Metadata status={message.msg_status}>
          <span className="by">{message.sent_by}</span>
          <span className="time">{message.msg_timeFormatted}</span>
          <span className="status">
            {message.msg_status === 'r' ||
            message.msg_status === 'w' ||
            message.msg_status === 's' ? (
              <MdDone />
            ) : (
              <MdDoneAll />
            )}
          </span>
        </styles.Metadata>
      </styles.Wrapper>
    </div>
  );
};

export default Message;
