/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloseOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
  SmileTwoTone,
} from '@ant-design/icons';
import { Button, Input, Upload } from 'antd';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useBot } from '~/hooks/bot/bot';
import { useMessages } from '~/hooks/messages/messages';
import RecoderVoiceMessage from '~/components/global/molecules/RecoderVoiceMessage';

import { Container, Wrapper } from './styles';

const TextComposer: React.FC = () => {
  const { sendMessage, sendMessageFile, isLoadingUploadMedia } = useMessages();
  const { isBotEnabled, updateBotStatus } = useBot();

  const [isPickEmojiVisible, setIsPickEmojiVisible] = useState(false);

  const inputRef = React.useRef<any>(null);
  const [messageText, setMessageText] = useState('');

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setMessageText(messageTextOld => `${messageTextOld}${emojiObject.emoji}`);
    setIsPickEmojiVisible(false);
  };

  const handleSendMessage = () => {
    sendMessage(messageText);
    setMessageText('');
  };

  return (
    <Wrapper>
      {isPickEmojiVisible ? (
        <div
          style={{
            position: 'absolute',
            marginLeft: '10px',
            marginTop: '-325px',
          }}
        >
          <Picker
            onEmojiClick={onEmojiClick}
            preload
            groupNames={{
              smileys_people: intl.get('text_composer.emojis.smileys_people'),
              animals_nature: intl.get('text_composer.emojis.animals_nature'),
              food_drink: intl.get('text_composer.emojis.food_drink'),
              travel_places: intl.get('text_composer.emojis.travel_places'),
              activities: intl.get('text_composer.emojis.activities'),
              objects: intl.get('text_composer.emojis.objects'),
              symbols: intl.get('text_composer.emojis.symbols'),
              flags: intl.get('text_composer.emojis.flags'),
              recently_used: intl.get('text_composer.emojis.recently_used'),
            }}
            groupVisibility={{
              recently_used: false,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <Container>
        {isLoadingUploadMedia ? (
          <>
            <div
              style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
            >
              <Button
                type="text"
                icon={<LoadingOutlined style={{ fontSize: 25 }} spin />}
              />
              <span>{intl.get('text_composer.sending')}</span>
            </div>
          </>
        ) : (
          <>
            {isPickEmojiVisible && (
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  setIsPickEmojiVisible(false);
                }}
              />
            )}

            <Button
              type="text"
              shape="circle"
              icon={isPickEmojiVisible ? <SmileTwoTone /> : <SmileOutlined />}
              value={messageText}
              onClick={() => {
                setIsPickEmojiVisible(!isPickEmojiVisible);
              }}
            />

            <Upload
              name="msg_type"
              showUploadList={false}
              onChange={info => {
                sendMessageFile(info.file.originFileObj);
              }}
            >
              <Button shape="circle" type="text" icon={<PaperClipOutlined />} />
            </Upload>

            <Input.TextArea
              className="inputText"
              placeholder={intl.get('text_composer.type_a_message')}
              autoSize={{ minRows: 1, maxRows: 5 }}
              ref={inputRef}
              value={messageText}
              onChange={e => {
                setMessageText(e.target.value);
              }}
              onKeyDown={event => {
                if (isBotEnabled) {
                  updateBotStatus(false);
                }

                if (event.keyCode === 13 && !event.shiftKey) {
                  event.preventDefault();

                  handleSendMessage();
                }
              }}
            />

            {messageText ? (
              <Button
                shape="circle"
                type="primary"
                icon={<SendOutlined />}
                onClick={() => {
                  handleSendMessage();
                }}
              />
            ) : (
              <RecoderVoiceMessage />
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default TextComposer;
