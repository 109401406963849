import React from 'react';
import { Card, Avatar, Typography, Form, Input, Button, Checkbox } from 'antd';
import intl from 'react-intl-universal';
import { Wrapper, Container, Profile } from './styles';
import { stringToHslColor } from '~/util/stringToHslColor';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { useUser, UserProps, UserPasswordProps } from '~/hooks/user';
import TabBar from '~/components/global/organisms/TabBar';

const Account: React.FC = () => {
  const { user, isLoading, updateUser, changePassword } = useUser();

  const [formChangePassword] = Form.useForm();

  const handleUpdateUser = (data: UserProps) => {
    updateUser(data);
  };

  const handleUpdatePassword = (data: UserPasswordProps) => {
    formChangePassword.resetFields();
    changePassword(data);
  };

  if (isLoading || !user) {
    return (
      <Container>
        <Card loading bordered={false} />
      </Container>
    );
  }
  return (
    <Wrapper>
      <Container>
        <Profile>
          <Avatar
            size={68}
            style={{
              backgroundColor: stringToHslColor(user.name),
            }}
          >
            {createLetterAvatar(user.name)}
          </Avatar>
          <Typography.Title level={4}>{user.name}</Typography.Title>
        </Profile>
        <Card>
          <Form
            layout="vertical"
            initialValues={user}
            onFinish={handleUpdateUser}
          >
            <Form.Item name="first_name" label={intl.get('profile.name')}>
              <Input placeholder={intl.get('profile.name_placeholder')} />
            </Form.Item>
            <Form.Item name="last_name" label={intl.get('profile.surname')}>
              <Input placeholder={intl.get('profile.surname_placeholder')} />
            </Form.Item>
            <Form.Item
              name="username"
              label={intl.get('profile.username')}
              rules={[
                {
                  required: true,
                  message: intl.get('profile.username_required'),
                },
              ]}
            >
              <Input placeholder={intl.get('profile.username_placeholder')} />
            </Form.Item>
            <Form.Item name="email" label={intl.get('profile.email')}>
              <Input
                type="email"
                placeholder={intl.get('profile.email_placeholder')}
              />
            </Form.Item>
            <Button type="primary" ghost htmlType="submit" block>
              {intl.get('buttons.save')}
            </Button>
          </Form>
        </Card>

        <Card title={intl.get('profile.change_password.title')}>
          <Form
            form={formChangePassword}
            layout="vertical"
            onFinish={handleUpdatePassword}
          >
            <Form.Item
              name="new_password1"
              label={intl.get('profile.change_password.new_password')}
              rules={[
                {
                  required: true,
                  message: intl.get(
                    'profile.change_password.new_password_required',
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="new_password2"
              label={intl.get('profile.change_password.confirm_password')}
              rules={[
                {
                  required: true,
                  message: intl.get(
                    'profile.change_password.confirm_password_required',
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        intl.get(
                          'profile.change_password.confirm_password_error',
                        ),
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button type="primary" ghost htmlType="submit" block>
              {intl.get('buttons.save')}
            </Button>
          </Form>
        </Card>

        <Card title={intl.get('profile.access_permissions.title')}>
          <Checkbox.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
            disabled
            defaultValue={user.apps}
            options={[
              {
                label: intl.get('profile.access_permissions.zapform'),
                value: 'zapform',
              },
              {
                label: intl.get(
                  'profile.access_permissions.zapform_change_form',
                ),
                value: 'zapform.change_form',
              },
              {
                label: intl.get('profile.access_permissions.inspira'),
                value: 'inspira',
              },
              {
                label: intl.get('profile.access_permissions.zapdelivery'),
                value: 'zapdelivery',
              },
              {
                label: intl.get('profile.access_permissions.zapchamado'),
                value: 'zapchamado',
              },
              {
                label: intl.get(
                  'profile.access_permissions.zapchamado_generate_qr',
                ),
                value: 'zapchamado.generate_qr',
              },
              {
                label: intl.get('profile.access_permissions.zapchamado_chat'),
                value: 'zapchamado.chat',
              },
              {
                label: intl.get('profile.access_permissions.zapchamado_report'),
                value: 'zapchamado.report',
              },
            ]}
          />
        </Card>
      </Container>
      <TabBar />
    </Wrapper>
  );
};

export default Account;
