import React, { createContext, useContext } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import isEmpty from '~/util/isEmpty';
import { FieldProps } from '~/@types/fields';
import formatPhoneNumber from '~/util/formatPhoneNumber';
import apiWhatsApp from '~/services/apiWhatsApp';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';

export interface ContactResponseProps {
  id: number;
  name: string;
  number: string;
  address: string;
}

interface IContactProfile {
  id: {
    server: string;
    user: string;
    _serialized: string;
  };
  avatar?: string;
}

export interface ContactProps {
  id: number;
  name: string;
  id_name: string;
  number: string;
  number_formatted: string;
  address: string;
}

interface ContactContextData {
  contact?: ContactProps;
  isLoading: boolean;
  isError: boolean;
  updateContact: (data: ContactResponseProps) => void;
  updateContactField: (field: FieldProps) => void;
  deleteContact: () => void;
  refetch: () => void;
  isFetching: boolean;
  updateAvatar: () => Promise<void>;
  isLoadingAvatar: boolean;
}

export const ContactContext = createContext<ContactContextData>(
  {} as ContactContextData,
);

interface ContactProviderProps {
  contactId?: number;
}

const ContactProvider: React.FC<ContactProviderProps> = ({
  children,
  contactId: externalContactId,
}) => {
  const { environment } = useEnvironment();
  const { contactId } = useParams<ParamsProps>();
  const history = useHistory();

  const { data: contact, isLoading, isError, isFetching, refetch } = useQuery(
    [
      `contact${contactId || externalContactId}`,
      contactId,
      externalContactId,
      environment?.id,
    ],
    async () => {
      if (!environment || (!contactId && !externalContactId)) {
        return undefined;
      }
      const response = await api.get(
        `zc/${environment.id}/client/${contactId || externalContactId}/`,
      );

      const { data } = response;
      return formatterContact(data);
    },
  );

  const formatterContact = (data: ContactResponseProps): ContactProps => {
    const contactResponse: ContactProps = {
      ...data,
      number_formatted: formatPhoneNumber(data.number),
      id_name: `#${data.id} - ${data.name}`,
    };
    return contactResponse;
  };

  const getAvatarContact = async (value?: string): Promise<string> => {
    try {
      const response = await apiWhatsApp.post<IContactProfile>(
        '/contacts/profile',
        {
          phoneNumber: value || contact?.number,
        },
      );

      const { data } = response;

      if (data.avatar) {
        return data.avatar;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const { mutateAsync: updateAvatar, isLoading: isLoadingAvatar } = useMutation(
    async (): Promise<void> => {
      if (!contact) {
        return;
      }

      let avatar = '';
      try {
        const response = await apiWhatsApp.post<IContactProfile>(
          '/contacts/profile',
          {
            phoneNumber: contact?.number,
          },
        );

        const { data } = response;

        if (data.avatar) {
          avatar = data.avatar;
        } else {
          toast.warn('A foto de perfil do contato é privada');
        }
      } catch (error) {
        toast.error('Não foi possivel atualizar foto de perfil do contato');
      }

      const formattedContact: ContactResponseProps = {
        ...contact,
        address: avatar,
      };

      await updateContact(formattedContact);
    },
  );

  const updateContact = async (data: ContactResponseProps) => {
    if (isEmpty(data)) {
      return;
    }

    const formattedNumber = clearSpecialCharacters(data.number);

    let avatar = '';
    if (formattedNumber !== contact?.number) {
      avatar = await getAvatarContact(formattedNumber);
    }

    try {
      await api.put(
        `/zc/${environment?.id}/client/${contactId || externalContactId}/`,
        {
          ...data,
          ...(avatar !== '' && { address: avatar }),
          ...(data.number && { number: formattedNumber }),
        },
      );

      refetch();
    } catch (error) {
      toast.error(intl.get('contact.update.error_update'));
    }
  };

  const updateContactField = async (field: FieldProps) => {
    if (!contact) {
      return;
    }

    let formattedContact = {
      ...contact,
      ...field,
    };

    if (formattedContact.number !== contact?.number) {
      const avatar = await getAvatarContact(formattedContact.number);

      formattedContact = { ...formattedContact, address: avatar };
    }

    try {
      await api.patch(
        `/zc/${environment?.id}/client/${contactId || externalContactId}/`,
        formattedContact,
      );

      refetch();
    } catch (error) {
      toast.error(intl.get('contact.update.error_update_field'));
    }
  };

  const deleteContact = async () => {
    try {
      await api.delete(
        `/zc/${environment?.id}/client/${contactId || externalContactId}/`,
      );
      toast.success(
        intl.get('contact.delete.successfully_deleted', {
          name: contact?.name,
        }),
      );
      history.goBack();
    } catch (error) {
      toast.error(intl.get('contact.delete.error_deleted'));
    }
  };

  return (
    <ContactContext.Provider
      value={{
        contact,
        isLoading,
        isFetching,
        isError,
        updateContact,
        updateContactField,
        deleteContact,
        refetch,
        updateAvatar,
        isLoadingAvatar,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
function useContact(): ContactContextData {
  const context = useContext(ContactContext);

  if (!context) {
    throw new Error('useContact must be used within an ContactProvider');
  }

  return context;
}

export { ContactProvider, useContact };
