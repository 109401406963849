import React, { createContext, useContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';

type TokenProps = string | null;

interface SignInCredentialsProps {
  username: string;
  password: string;
}

export interface RecoveryAccountProps {
  email: string;
}
interface AuthContextData {
  token: TokenProps;
  signIn: (credentials: SignInCredentialsProps) => Promise<void>;
  signOut: () => void;
  recoveryPassword: (data: RecoveryAccountProps) => Promise<void>;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<TokenProps>(() => {
    const tokenLocalStorage = localStorage.getItem('@ZapForm:token');

    if (tokenLocalStorage) {
      api.defaults.headers.Authorization = `Token ${tokenLocalStorage}`;
      return tokenLocalStorage;
    }

    return null;
  });

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post('auth/login/', {
      username,
      password,
    });

    const newToken = response.data.key;
    api.defaults.headers.Authorization = `Token ${newToken}`;

    setToken(newToken);
    localStorage.setItem('@ZapForm:token', newToken);
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setToken(null);
  }, []);

  const recoveryPassword = async (data: RecoveryAccountProps) => {
    try {
      await api.post('auth/password/reset/', data);
    } catch (error) {
      toast.error('Não foi possível recuperar sua senha, verifique seu email');
    }
  };

  return (
    <AuthContext.Provider value={{ token, signIn, signOut, recoveryPassword }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
