import React from 'react';
import intl from 'react-intl-universal';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from 'antd/lib/typography/Text';
import { useChatContacts } from '~/hooks/whatsAppWeb/chatContacts';
import { useWhatsAppWeb } from '~/hooks/whatsAppWeb/whatsAppWeb';
import { Container } from './styles';
import ChatItem from '../../molecules/ChatItem';

const ChatList: React.FC = () => {
  const {
    contactsList,
    hasMore,
    moreContacts,
    totalContacts,
    isLoading,
  } = useChatContacts();

  const { quickSearch } = useWhatsAppWeb();

  if (!isLoading && totalContacts === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem',
        }}
      >
        <Text type="secondary">
          {intl.get('call_center_contact.list.no_contact')}
        </Text>
      </div>
    );
  }

  return (
    <Container id="scrollableDivListContacts">
      {quickSearch !== '' && (
        <div style={{ margin: '0.5rem auto' }}>
          <Text type="secondary">
            {intl.get('call_center_contact.list.counter', {
              total: totalContacts,
            })}
          </Text>
        </div>
      )}

      {isLoading && !totalContacts ? (
        <>
          <div style={{ padding: '0.25rem' }}>
            <Skeleton active avatar paragraph={{ rows: 1 }} />
          </div>
          <div style={{ padding: '0.25rem' }}>
            <Skeleton active avatar paragraph={{ rows: 1 }} />
          </div>
          <div style={{ padding: '0.25rem' }}>
            <Skeleton active avatar paragraph={{ rows: 1 }} />
          </div>
        </>
      ) : (
        <InfiniteScroll
          dataLength={contactsList.length}
          next={moreContacts}
          hasMore={hasMore}
          loader={
            <div
              style={{
                margin: '1rem auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
          }
          scrollableTarget="scrollableDivListContacts"
          style={{ overflow: 'none' }}
        >
          {contactsList.map(contact => (
            <ChatItem key={contact.id} data={contact} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default ChatList;
