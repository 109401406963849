import React from 'react';
import { useDropzone } from 'react-dropzone';
import intl from 'react-intl-universal';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useHistory, useParams } from 'react-router-dom';

import Chat from '../Chat';

import NavBar from '~/components/global/organisms/NavBar';
import { useContact } from '~/hooks/contacts/contact';

import { MessagesProvider } from '~/hooks/messages/messages';

import { Header, Content } from './styles';
import ChatTitleBar from '../ChatTitleBar';
import { BotProvider } from '~/hooks/bot/bot';
import { ParamsProps } from '~/@types/params';

const Contact: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const history = useHistory();
  const { isLoading, isError, contact } = useContact();
  const { getRootProps } = useDropzone();

  if (isError) {
    Modal.warning({
      title: intl.get('contact.no_found.title'),
      content: intl.get('contact.no_found.content'),
    });
    history.push(`/c/${environmentId}/whatsappweb`);
  }

  if (isLoading || !contact) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingOutlined style={{ fontSize: 42 }} spin />
      </div>
    );
  }

  return (
    <>
      <BotProvider contactId={contact.id}>
        <Header>
          <NavBar title={contact.name} isBack>
            <ChatTitleBar />
          </NavBar>
        </Header>
        <Content {...getRootProps({ className: 'dropzone' })}>
          <MessagesProvider>
            <Chat />
          </MessagesProvider>
        </Content>
      </BotProvider>
    </>
  );
};

export default Contact;
