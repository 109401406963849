import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  border-radius: var(--radius);
  padding: 0 0.5rem;
  justify-content: flex-end;
  border-bottom: var(--border);

  gap: 1rem;
`;
