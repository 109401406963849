/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Upload,
  Button,
  Form,
  Image,
  Input,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, { ReactElement, useState } from 'react';
import { CSSProperties } from 'styled-components';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import Linkify from '../Linkify';
import { Container, Label, Value } from './styles';
import { FieldProps } from '~/@types/fields';
import apiAttachments from '~/services/apiAttachments';
import Document from '../Document';
import Audio from '../Audio';
import Video from '../Video';

interface BaseFieldProps {
  label: string;
  editable?: boolean;
  type?: 'text' | 'phone' | 'image' | 'audio' | 'video' | 'document';
  small?: boolean;
  name?: string;
  value?: string | number | null | any;
  url?: string | null;
  onChange?: (field: any) => void;
  style?: CSSProperties;
  copyable?: boolean;
}

const BaseField: React.FC<BaseFieldProps> = ({
  children,
  small = false,
  type = 'text',
  editable = false,
  label,
  name,
  value,
  url,
  onChange,
  style,
  copyable = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onFinish = (field: FieldProps) => {
    if (type === 'phone' && name) {
      // eslint-disable-next-line no-param-reassign
      field[name] = String(field[name]).replace(/[^0-9]+/g, '');
    }
    if (onChange) {
      onChange(field);
    }
    handleEditing();
  };

  const getTextValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form initialValues={{ [name]: value }} onFinish={onFinish}>
          <Form.Item name={name}>
            <Input.TextArea autoSize value={value || ''} />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Linkify>
        <Typography.Paragraph
          type="secondary"
          ellipsis={{ rows: 3, expandable: true }}
          copyable={copyable}
        >
          {value === null || value === '' || value === undefined
            ? intl.get('ui.blank')
            : value}
        </Typography.Paragraph>
      </Linkify>
    );
  };

  const getPhoneValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form
          initialValues={{ [name]: `+${String(value)}` }}
          onFinish={onFinish}
        >
          <Form.Item
            name={name}
            rules={[
              {
                required: true,
                message: 'Por favor insira o número do contato!',
              },
              () => ({
                validator(rule, phone) {
                  if (phone && isPossiblePhoneNumber(phone)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Número incorreto!'));
                },
              }),
            ]}
          >
            <PhoneInput
              placeholder="Número"
              value=""
              defaultCountry="BR"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Typography.Paragraph
        type="secondary"
        ellipsis={{ rows: 3, expandable: true }}
        copyable={copyable}
      >
        {value === null || value === '' || value === undefined
          ? intl.get('ui.blank')
          : value}
      </Typography.Paragraph>
    );
  };

  const getFileValue = (): ReactElement => {
    async function checkTypeFile(file: any) {
      if (file.type.match(/image/g) !== null) {
        return 'image';
      }
      if (file.type.match(/audio/g) !== null) {
        return 'audio';
      }
      if (file.type.match(/video/g) !== null) {
        return 'video';
      }
      return 'document';
    }

    const sendFile = async (file: any) => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const typeFile = await checkTypeFile(file);

        try {
          const response = await apiAttachments.post(`/attachments`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const { data } = response;

          const newField = {
            [`${name}`]: {
              url: String(data.url),
              _type: typeFile,
            },
          };
          if (onChange) {
            onChange(newField);
          }
        } catch (error) {
          toast.error(intl.get('message.error_sending_file'));
        }
      }
    };

    if (isEditing && name) {
      return (
        <>
          <Upload
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            customRequest={async (options: any) => {
              if (options.file) {
                await sendFile(options.file);
                options.onSuccess('Ok');
                handleEditing();
              }
            }}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>
              {intl.get('buttons.click_to_upload')}
            </Button>
          </Upload>
          <br />
        </>
      );
    }

    if (url === null || url === '' || url === undefined) {
      return <>{intl.get('ui.blank')}</>;
    }

    switch (type) {
      case 'image':
        return (
          <Image
            src={url}
            alt={url}
            style={{ maxWidth: '400px', maxHeight: '400px' }}
          />
        );
      case 'audio':
        return <Audio url={url} />;
      case 'video':
        return <Video url={url} />;
      default:
        return <Document url={url} />;
    }
  };

  const getBooleanValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form
          initialValues={{ [name]: `+${String(value)}` }}
          onFinish={onFinish}
        >
          <Form.Item name={name} valuePropName="checked">
            <Switch checkedChildren="Sim" unCheckedChildren="Não" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Typography.Paragraph
        type="secondary"
        ellipsis={{ rows: 3, expandable: true }}
        copyable={copyable}
      >
        {value || value === 'True' ? 'Sim' : 'Não'}
      </Typography.Paragraph>
    );
  };

  const getTypeValue = (): ReactElement => {
    if (children) {
      return <div style={{ marginBottom: '1rem' }}>{children}</div>;
    }

    if (typeof value === 'boolean' || value === 'True' || value === 'False') {
      return getBooleanValue();
    }

    if (type === 'phone') {
      return getPhoneValue();
    }

    if (
      type === 'image' ||
      type === 'document' ||
      type === 'audio' ||
      type === 'video'
    ) {
      return getFileValue();
    }

    return getTextValue();
  };

  return (
    <Container small={small}>
      <Label>
        <Typography.Title ellipsis level={5} style={{ marginBottom: '0' }}>
          {label}
        </Typography.Title>
        {editable && (
          <div className="edit">
            <Tooltip title="Editar">
              <Button
                type="link"
                size="large"
                icon={<EditOutlined />}
                onClick={() => handleEditing()}
              />
            </Tooltip>
          </div>
        )}
      </Label>
      <Value style={style}>{getTypeValue()}</Value>
    </Container>
  );
};

export default BaseField;
