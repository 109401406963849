import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface MessageDirectVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface MessageTypeVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface MessageStatusVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface WrapperProps {
  dir: string;
}

interface ContainerProps {
  type: string;
}

interface MetadataTypeProps {
  status: 'r' | 'w' | 's' | 'a' | 'd';
}

const messageDirectVariations: MessageDirectVariationsProps = {
  sent: css`
    float: right;
    background: #e1ffc7;
    margin-right: 1rem;
    border-radius: 5px 0px 5px 5px;
    &:after {
      border-width: 0px 0 10px 10px;
      border-color: transparent transparent transparent #e1ffc7;
      right: -10px;
    }
  `,

  received: css`
    float: left;
    background: #fff;
    margin-left: 1rem;
    border-radius: 0px 5px 5px 5px;
    &:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
      left: -10px;
    }
  `,

  alert: css`
    max-width: 100%;
    color: #fff;
    background: rgb(117 117 117 / 60%);
    border-radius: 5px;
    text-align: center;
    &:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent transparent transparent transparent;
      left: -10px;
    }
  `,
};

const messageTypeVariations: MessageTypeVariationsProps = {
  default: css``,
  im: css`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 350px;
    }
  `,
};

const messageStatusVariations: MessageStatusVariationsProps = {
  r: css`
    color: var(--gray-7);
  `,
  w: css`
    color: var(--blue-color);
  `,
  s: css`
    color: var(--blue-color);
  `,
  a: css`
    color: var(--gray-7);
  `,
  d: css`
    color: var(--blue-color);
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  clear: both;
  line-height: 1rem;
  padding: 0.5rem;
  position: relative;
  margin: 0.5rem 0;
  min-width: 40%;
  max-width: 80%;
  word-wrap: break-word;

  ${props => messageDirectVariations[props.dir || 'sent']}

  .more {
    cursor: pointer;
    position: absolute;
    right: 10px;
    display: none;
  }
  &:hover {
    .more {
      display: block;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
  }

  .reply {
    background: rgb(0, 0, 0, 0.05);
    padding: 8px 6px;
    padding-right: 40px;
    margin: 4px;
    border-radius: 4px;
    border-left: 4px solid var(--primary-color);
    max-height: 65px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Container = styled.div<ContainerProps>`
  ${props => messageTypeVariations[props.type || 'default']}
`;

export const Metadata = styled.div<MetadataTypeProps>`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.75rem;
  color: var(--gray-7);

  .status {
    font-size: 1rem;
    ${props => messageStatusVariations[props.status || 'r']}
  }
`;
