import React from 'react';

import ReactPlayer from 'react-player/lazy';

interface VideoProps {
  url: string;
}
const Video: React.FC<VideoProps> = ({ url }) => {
  return (
    <div>
      <ReactPlayer
        controls
        width="100%"
        url={url}
        style={{ maxWidth: '400px', maxHeight: '400px' }}
      />
    </div>
  );
};

export default Video;
