import React from 'react';

import { Select } from 'antd';

import { useEnvironments } from '~/hooks/environments/environments';

import { useEnvironment } from '~/hooks/environments/environment';

interface EnvironmentOptions {
  name: string;
  label: string;
  value: number;
}

const SelectEnvironment: React.FC = () => {
  const { environmentsList, isLoading } = useEnvironments();
  const { environment, setEnvironmentCurrent } = useEnvironment();

  if (isLoading || !environmentsList) {
    return <Select loading disabled />;
  }

  const formattedEnvironmentsList: EnvironmentOptions[] = environmentsList.map(
    env => ({
      name: env.name,
      label: env.name,
      value: env.id,
    }),
  );

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      value={environment ? environment.id : formattedEnvironmentsList[0]?.value}
      onChange={value => setEnvironmentCurrent(Number(value))}
      filterOption={(input, option) =>
        option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA?.name.toLowerCase().localeCompare(optionB.name.toLowerCase())
      }
      options={formattedEnvironmentsList}
      notFoundContent={null}
    />
  );
};

export default SelectEnvironment;
