import { Tag } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import ChatList from '~/components/CallCenter/organisms/ChatList';
import CallCenterPanel from '~/components/CallCenter/organisms/Panel';
import NavBar from '~/components/global/organisms/NavBar';
import { CallCenterProvider } from '~/hooks/callCenter/callCenter';
import { ChatAttendancesProvider } from '~/hooks/callCenter/chatAttendances';
import { useEnvironment } from '~/hooks/environments/environment';
import { Container, Header, Content } from './styles';
import TabBar from '~/components/global/organisms/TabBar';
import QRCodeModal from '~/components/global/molecules/QRCodeModal';
import NotificationPermissionAlert from '~/components/global/atoms/NotificationPermissionAlert';

const CallCenter: React.FC = () => {
  const { isLoading, totalAttendances } = useEnvironment();

  return (
    <Container>
      <CallCenterProvider>
        <Header>
          <NavBar>
            {!isLoading && (
              <Tag>
                {intl.get('attendance.counter', {
                  total: totalAttendances,
                })}
              </Tag>
            )}
            <QRCodeModal />
          </NavBar>

          <CallCenterPanel />
          <NotificationPermissionAlert />
        </Header>
        <Content>
          <ChatAttendancesProvider>
            <ChatList />
          </ChatAttendancesProvider>
        </Content>
      </CallCenterProvider>
      <TabBar />
    </Container>
  );
};

export default CallCenter;
