export const stringToHslColor = (str: string): string => {
  try {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + (hash * 32 - hash);
    }

    const h = hash % 360;
    return `hsl(${h}, 68%, 38%)`;
  } catch (error) {
    return '#cccccc';
  }
};
