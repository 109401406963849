import styled, { css } from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
`;

export const Value = styled.div``;

interface ContainerProps {
  small?: boolean;
}

const smallLayout = css`
  h5 {
    font-size: 0.9rem;
    margin: 0;
  }

  div.ant-typography-secondary {
    margin-bottom: 0.25rem;
  }
  font-size: 0.8rem;
  margin-bottom: 0rem;
`;
export const Container = styled.div<ContainerProps>`
  .edit {
    opacity: 0;
  }
  :hover {
    .edit {
      opacity: 1;
    }
  }
  ${props => props.small && smallLayout}
`;
