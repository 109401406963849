import React from 'react';
import ReactLinkify from 'react-linkify';

const Linkify: React.FC = ({ children }) => {
  return (
    <div>
      <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {children}
      </ReactLinkify>
    </div>
  );
};

export default Linkify;
