import { UserAddOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Button, Modal, Input, Form, Tooltip } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { ParamsProps } from '~/@types/params';

// import { Container } from './styles';

interface INewResponsible {
  name: string;
  number: string;
}

interface NewResponsibleProps {
  id: number;
  name: string;
}

interface ModalNewResponsibleProps {
  type?: 'text' | 'icon';
  isOpenAfterCreated?: boolean;
  onSetResponsibleSelected?: (responsibleId: number) => void;
}

const ModalNewResponsible: React.FC<ModalNewResponsibleProps> = ({
  type,
  isOpenAfterCreated = true,
  onSetResponsibleSelected,
}) => {
  const { environmentId } = useParams<ParamsProps>();
  const { environment } = useEnvironment();
  const { refetch } = useResponsibles();
  const [isVisible, setIsVisible] = useState(false);

  const history = useHistory();

  const [form] = Form.useForm();

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const createNewResponsible = async (responsible: INewResponsible) => {
    try {
      const response = await api.post(
        `/zc/${environment?.id}/location/`,
        responsible,
      );

      const newResponsible: NewResponsibleProps = response.data;

      if (isOpenAfterCreated) {
        history.push(`/c/${environmentId}/responsibles/${newResponsible.id}`);
      }

      if (refetch) {
        refetch();
      }
      if (onSetResponsibleSelected) {
        onSetResponsibleSelected(newResponsible.id);
      }

      handleCloseModal();
    } catch (error) {
      toast.error(intl.get('responsible.create.error_creating'));
    }
  };

  return (
    <div>
      {type === 'icon' ? (
        <Tooltip title={intl.get('responsible.create.title')}>
          <Button
            type="text"
            size="large"
            icon={<UserAddOutlined />}
            onClick={handleOpenModal}
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          size="large"
          icon={<UserAddOutlined />}
          onClick={handleOpenModal}
        >
          {intl.get('responsible.create.title')}
        </Button>
      )}

      <Modal
        title={intl.get('responsible.create.title')}
        visible={isVisible}
        centered
        footer={null}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={values => createNewResponsible(values)}
        >
          <Form.Item
            label={intl.get('responsible.name')}
            name="name"
            rules={[
              {
                required: true,
                message: intl.get('responsible.create.name_error'),
              },
            ]}
          >
            <Input
              placeholder={intl.get('responsible.create.name_placeholder')}
            />
          </Form.Item>
          <Form.Item label={intl.get('responsible.address')} name="address">
            <Input.TextArea
              placeholder={intl.get('responsible.create.address_placeholder')}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            {intl.get('responsible.create.create_responsible')}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalNewResponsible;
