import styled from 'styled-components';

import { Layout as LayoutAntd } from 'antd';

export const Layout = styled(LayoutAntd)`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled(LayoutAntd.Content)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;
