import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 0 0 60px;
`;

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
  padding: 0.5rem;

  background: #fff;

  .inputText {
    border-radius: 1rem;
  }
`;
