import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Account from '~/pages/Account';
import AccountsRecovery from '~/pages/AccountsRecovery';
import Page404 from '~/pages/404';
import CallCenter from '~/pages/CallCenter';
import AttendanceCallCenter from '~/pages/CallCenter/Attendance';
import WhatsAppWeb from '~/pages/WhatsAppWeb';
import WhatsAppWebContact from '~/pages/WhatsAppWeb/Contact';
// import Dashboard from '~/pages/Dashboard';
import More from '~/pages/More';
import PageRedirect from '~/pages/Redirect';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/accounts/recovery" exact component={AccountsRecovery} />
    <Route
      path="/c/:environmentId/account"
      exact
      component={Account}
      isPrivate
    />

    <Route path="/redirect" exact component={PageRedirect} isPrivate />

    <Route path="/c/:environmentId/more" exact component={More} isPrivate />

    <Route
      path="/c/:environmentId/callcenter"
      exact
      component={CallCenter}
      isPrivate
    />
    <Route
      path="/c/:environmentId/callcenter/:attendanceId"
      exact
      component={AttendanceCallCenter}
      isPrivate
    />

    <Route
      path="/c/:environmentId/whatsappweb/"
      exact
      component={WhatsAppWeb}
      isPrivate
    />
    <Route
      path="/c/:environmentId/whatsappweb/:contactId"
      exact
      component={WhatsAppWebContact}
      isPrivate
    />

    <Route exact component={Page404} />
  </Switch>
);

export default Routes;
