import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {
  CommentOutlined,
  // DashboardOutlined,
  MoreOutlined,
  PlusOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { Link, useLocation, useParams } from 'react-router-dom';

import { Badge } from 'antd';
import { Container, Menu, MenuItem } from './styles';
import { useUnreadMessages } from '~/hooks/messages/unreadMessages';
import ModalNewAttendance from '../../molecules/ModalNewAttendance';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';

interface RouteProps {
  key: string;
  title: string;
  to: string;
  icon?: React.ReactNode;
  childrens?: RouteProps[];
}

const TabBar: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const { pathname } = useLocation();
  const { environment } = useEnvironment();
  const { totalUnreadMessages } = useUnreadMessages();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const handleOpenModal = () => {
    setIsVisibleModal(true);
  };
  const handleCloseModal = () => {
    setIsVisibleModal(false);
  };

  const pages: RouteProps[] = [
    // {
    //   key: 'dashboard',
    //   title: intl.get('dashboard.title'),
    //   to: '/dashboard',
    //   icon: <DashboardOutlined />,
    // },
    {
      key: 'whatsappweb',
      title: intl.get('whatsapp_web.title_mobile'),
      to: `/c/${environmentId}/whatsappweb`,
      icon: (
        <Badge count={totalUnreadMessages}>
          <WhatsAppOutlined />
        </Badge>
      ),
    },
    {
      key: 'callcenter',
      title: 'Cards',
      to: `/c/${environmentId}/callcenter`,
      icon: (
        <Badge count={totalUnreadMessages}>
          <CommentOutlined />
        </Badge>
      ),
    },
    {
      key: 'more',
      title: intl.get('more.title'),
      to: `/c/${environmentId}/more`,
      icon: <MoreOutlined rotate={90} />,
    },
  ];

  const activeLink = [
    ...pages
      .filter(route => pathname.includes(route.key))
      .map(route => route.key),
  ];

  return (
    <Container>
      <ModalNewAttendance
        isOpenModal={isVisibleModal}
        onCloseModal={() => handleCloseModal()}
      />
      <Menu>
        {environment?.status && environment?.status.length > 0 && (
          <MenuItem
            key="new-attendance"
            style={{
              color: 'rgba(0, 0, 0, 0.65)',
            }}
            onClick={() => handleOpenModal()}
          >
            <div className="icon">
              <PlusOutlined />
            </div>
            {intl.get('attendance.title')}
          </MenuItem>
        )}
        {pages.map(page => (
          <MenuItem
            key={page.key}
            style={{
              color:
                activeLink[0] === page.key
                  ? 'var(--primary-color)'
                  : 'rgba(0, 0, 0, 0.65)',
            }}
          >
            <Link to={page.to} key={page.key}>
              <div className="icon">{page.icon}</div>
              {page.title}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default TabBar;
