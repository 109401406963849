import React from 'react';
import { Button } from 'antd';
import intl from 'react-intl-universal';
import { useLocales } from '~/hooks/locales';

const ChangeLocale: React.FC = () => {
  const { currentLocale, changeCurrentLocale } = useLocales();
  return (
    <Button
      onClick={() =>
        currentLocale === 'pt-BR'
          ? changeCurrentLocale('es')
          : changeCurrentLocale('pt-BR')
      }
      block
    >
      {intl.get('language')}
    </Button>
  );
};

export default ChangeLocale;
