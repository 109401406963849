import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useSound from 'use-sound';
import Push from 'push.js';
import intl from 'react-intl-universal';
import api from '~/services/api';
import isEmpty from '~/util/isEmpty';
import { useEnvironment } from '~/hooks/environments/environment';
import logo from '~/assets/logo.png';
import soundNotification from '~/assets/notification.mp3';

export interface UnreadMessagesResponseProps {
  order_id: number;
  client_id: number;
  client_number: string;
  unread_msg: number;
}

export interface UnreadMessagesProps {
  [x: number]: number;
}

interface UnreadMessagesContextData {
  unreadMessagesList?: UnreadMessagesProps;
  isLoading: boolean;
  refetch: () => void;
  totalUnreadMessages: number;
  notificationsIsGuaranteed: boolean;
  requestPermissionNotification: () => void;
}
const UnreadMessagesContext = createContext<
  UnreadMessagesContextData | undefined
>(undefined);

const UnreadMessagesProvider: React.FC = ({ children }) => {
  const [
    notificationsIsGuaranteed,
    setNotificationsIsGuaranteed,
  ] = useState(() => Push.Permission.has());
  const [play] = useSound(soundNotification);

  const { environment } = useEnvironment();

  const [
    oldUnreadMessagesList,
    setOldUnreadMessagesList,
  ] = useState<UnreadMessagesProps>({} as UnreadMessagesProps);

  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);

  const { data: unreadMessagesList, isLoading, refetch } = useQuery(
    [`unreadMessagesList`, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }

      const response = await api.get(
        `/zc/${environment.id}/order/unread_messages/`,
        {
          params: {
            offset: 0,
            limit: 500,
          },
        },
      );

      const unreadMessagesListFiltered = response.data.filter(
        (
          v: UnreadMessagesResponseProps,
          i: number,
          a: UnreadMessagesResponseProps[],
        ) =>
          a.findIndex(
            (t: UnreadMessagesResponseProps) => t.client_id === v.client_id,
          ) === i,
      );

      const formattedUnreadMessagesList: UnreadMessagesProps = Object.assign(
        {},
        ...unreadMessagesListFiltered.map(
          (item: UnreadMessagesResponseProps) => ({
            [item.client_id]: item.unread_msg,
          }),
        ),
      );

      return formattedUnreadMessagesList;
    },
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    },
  );

  const showPushNotification = () => {
    Push.create(intl.get('message.new_message'), {
      body: intl.get('message.new_message_info'),
      icon: logo,
      onClick: () => {
        window.focus();
      },
    });
  };

  const checkUnreadMessagesList = () => {
    if (!unreadMessagesList) {
      return;
    }

    if (isEmpty(unreadMessagesList)) {
      setOldUnreadMessagesList(unreadMessagesList);
      setTotalUnreadMessages(0);
      return;
    }

    let newTotalUnreadMessages = 0;
    Object.entries(unreadMessagesList).forEach(([key, value]) => {
      if (value > oldUnreadMessagesList[Number(key)]) {
        play();
        showPushNotification();
      }
      newTotalUnreadMessages += value;
    });
    setTotalUnreadMessages(newTotalUnreadMessages);
    setOldUnreadMessagesList(unreadMessagesList);
  };

  useEffect(() => {
    checkUnreadMessagesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessagesList]);

  useEffect(() => {
    setNotificationsIsGuaranteed(Push.Permission.has());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Push.Permission.has()]);

  const requestPermissionNotification = () => {
    Push.Permission.request(
      () => {
        Push.Permission.GRANTED;
      },
      () => {
        Push.Permission.DENIED;
      },
    );
  };

  return (
    <UnreadMessagesContext.Provider
      value={{
        unreadMessagesList,
        totalUnreadMessages,
        isLoading,
        refetch,
        notificationsIsGuaranteed,
        requestPermissionNotification,
      }}
    >
      {children}
    </UnreadMessagesContext.Provider>
  );
};
function useUnreadMessages(): UnreadMessagesContextData {
  const context = useContext(UnreadMessagesContext);

  if (!context) {
    throw new Error(
      'useUnreadMessages must be used within an UnreadMessagesProvider',
    );
  }

  return context;
}

export { UnreadMessagesProvider, useUnreadMessages };
