import { FileSearchOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import intl from 'react-intl-universal';
import React, { useState } from 'react';
import AttendanceLog from '~/components/global/molecules/AttendanceLog';

import { useAttendance } from '~/hooks/attendances/attendance';

const DrawerAttendanceLog: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  if (isLoading || !attendance) {
    return <Button type="text" size="large" loading />;
  }
  return (
    <div>
      <Button
        type="text"
        shape="circle"
        icon={<FileSearchOutlined />}
        onClick={() => showDrawer()}
      />

      <Drawer
        title={intl.get('call_center.title_bar.attendance_log')}
        placement="bottom"
        height="100%"
        onClose={onClose}
        visible={isDrawerVisible}
      >
        <AttendanceLog />
      </Drawer>
    </div>
  );
};

export default DrawerAttendanceLog;
