import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { useEnvironment } from '~/hooks/environments/environment';
import SelectEnvironment from '../SelectEnvironment';

const SelectEnvironmentModal: React.FC = () => {
  const { environment, isLoading } = useEnvironment();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (isLoading || !environment) {
    return <Button loading />;
  }

  return (
    <>
      <Button type="text" onClick={showModal}>
        <b>{environment?.name}</b>
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ padding: '2.5rem' }}
      >
        <SelectEnvironment />
      </Modal>
    </>
  );
};

export default SelectEnvironmentModal;
