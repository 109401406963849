import React, { createContext, useContext, useState } from 'react';
import { FiltersProps } from '~/@types/attendances';

export interface SettingsProps {
  title?: Array<
    | 'contact.avatar'
    | 'contact.id_name'
    | 'contact.number'
    | 'status.code_status'
  >;
  subTitles?: Array<
    | 'responsible.name'
    | 'responsible.address'
    | 'attendance.order_summary'
    | 'attendance.time_created'
    | 'attendance.time_createdThere'
    | 'attendance.time_status'
    | 'attendance.time_deadline'
    | 'attendance.time_timeout'
  >;
  sortBy?: {
    sort: 'asc' | 'desc';
    by: 'last_msg' | 'name' | 'number_unread_msgs' | 'id';
  };
}

interface CallCenterContextData {
  settings: SettingsProps;
  quickSearch?: string;
  filters: FiltersProps;
  setNewSettings: (newSettingsInput: SettingsProps) => void;
  resetSettings: () => void;
  setNewQuickSearch: (newQuickSearch: string) => void;
  setNewFilters: (newFiltersInput: FiltersProps) => void;
  cleanFilters: () => void;
}
const CallCenterContext = createContext<CallCenterContextData>(
  {} as CallCenterContextData,
);

const CallCenterProvider: React.FC = ({ children }) => {
  const defaultSettings: SettingsProps = {
    title: ['contact.avatar', 'contact.number'],
    subTitles: [
      'attendance.time_createdThere',
      'attendance.time_timeout',
      'attendance.order_summary',
    ],
    sortBy: { sort: 'desc', by: 'last_msg' },
  };

  const [quickSearch, setQuickSearch] = useState<string>('');
  const [settings, setSettings] = useState<SettingsProps>(() => {
    const settingsLocalStorage = localStorage.getItem(
      '@ZapForm:callCenterSettings',
    );

    if (settingsLocalStorage) {
      const formattedSettings: SettingsProps = JSON.parse(settingsLocalStorage);

      if (
        formattedSettings.title &&
        formattedSettings.subTitles &&
        formattedSettings.sortBy
      ) {
        return formattedSettings;
      }
    }

    localStorage.setItem(
      '@ZapForm:callCenterSettings',
      JSON.stringify(defaultSettings),
    );

    return defaultSettings;
  });

  const [filters, setFilters] = useState<FiltersProps>(() => {
    const filtersLocalStorage = localStorage.getItem(
      '@ZapForm:callCenterFilters',
    );

    if (filtersLocalStorage) {
      return JSON.parse(filtersLocalStorage);
    }

    return {} as FiltersProps;
  });

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  const setNewSettings = (newSettingsInput: SettingsProps) => {
    localStorage.setItem(
      '@ZapForm:callCenterSettings',
      JSON.stringify(newSettingsInput),
    );
    setSettings(newSettingsInput);
  };

  const resetSettings = () => {
    localStorage.setItem(
      '@ZapForm:callCenterSettings',
      JSON.stringify(defaultSettings),
    );
    setSettings(defaultSettings);
  };

  const setNewFilters = (newFiltersInput: FiltersProps) => {
    localStorage.setItem(
      '@ZapForm:callCenterFilters',
      JSON.stringify(newFiltersInput),
    );
    setFilters(newFiltersInput);
  };

  const cleanFilters = () => {
    localStorage.removeItem('@ZapForm:callCenterFilters');
    setFilters({} as FiltersProps);
  };

  return (
    <CallCenterContext.Provider
      value={{
        settings,
        setNewSettings,
        resetSettings,
        filters,
        setNewFilters,
        cleanFilters,
        quickSearch,
        setNewQuickSearch,
      }}
    >
      {children}
    </CallCenterContext.Provider>
  );
};
function useCallCenter(): CallCenterContextData {
  const context = useContext(CallCenterContext);

  if (!context) {
    throw new Error('useCallCenter must be used within an CallCenterProvider');
  }

  return context;
}

export { CallCenterProvider, useCallCenter };
