import { Input } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import Settings from '../../molecules/Settings';

import { useWhatsAppWeb } from '~/hooks/whatsAppWeb/whatsAppWeb';
import NewContactModal from '~/components/global/molecules/NewContactModal';

import { Container } from './styles';

const ContactsCallCenterPanel: React.FC = () => {
  const { setNewQuickSearch } = useWhatsAppWeb();

  const handleQuickSearch = (value: string) => {
    setNewQuickSearch(value);
  };

  const handleClearSeacrh = (value: string) => {
    if (!value) {
      setNewQuickSearch('');
    }
  };

  return (
    <Container>
      <Input.Search
        allowClear
        onSearch={handleQuickSearch}
        onChange={e => {
          handleClearSeacrh(e.target.value);
        }}
        placeholder={intl.get('contact.filters.search_contact_placeholder')}
        size="large"
      />

      <NewContactModal />

      <Settings />
    </Container>
  );
};

export default ContactsCallCenterPanel;
