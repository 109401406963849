import React, { useState } from 'react';
import {
  Form,
  Button,
  Modal,
  notification,
  Select,
  Spin,
  Row,
  Col,
} from 'antd';
import intl from 'react-intl-universal';
import { useMutation } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { AttendanceResponseProps } from '~/@types/attendance';
import { useEnvironment } from '~/hooks/environments/environment';
import api from '~/services/api';
import queryClient from '~/services/queryClient';
import { SelectOptions } from '~/@types/fields';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { ContactResponseProps } from '~/hooks/contacts/contacts';
import NewContactModal from '../NewContactModal';
import ModalNewResponsible from '~/components/Responsibles/ModalNewResponsible';
import { useStatus } from '~/hooks/status/status';
import { ParamsProps } from '~/@types/params';

type IAttendanceResponse = AttendanceResponseProps;

export interface ICreateAttendance {
  responsibleId: string;
  contactId: string;
  statusCode: string;
  order?: { [key: string]: string };
}

interface ModalNewAttendanceProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  contact?: SelectOptions;
  responsibleId?: number;
  statusCode?: string;
}

const ModalNewAttendance: React.FC<ModalNewAttendanceProps> = ({
  isOpenModal,
  onCloseModal,
  contact: contactExternal,
  responsibleId: responsibleIdExternal,
  statusCode: statusCodeExternal,
}) => {
  const { environmentId } = useParams<ParamsProps>();
  const { environment } = useEnvironment();
  const { isLoading: isLoadingStatus, statusList } = useStatus();

  const [form] = Form.useForm();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const createAttendance = useMutation(
    async ({
      contactId,
      responsibleId,
      statusCode,
    }: ICreateAttendance): Promise<IAttendanceResponse | undefined> => {
      if (!environment) {
        return undefined;
      }

      setIsLoading(true);
      const newAttendance = await api
        .post<IAttendanceResponse>(`/zc/${environment.id}/order/`, {
          client: contactId,
          location: responsibleId,
          order: { Origem: 'front' },
        })
        .then(response => {
          const { data } = response;
          notification.success({
            message: intl.get('modal_new_attendance.create.message'),
          });

          return data;
        })
        .catch(() => {
          notification.error({
            message: intl.get('modal_new_attendance.error.message'),
            description: intl.get('modal_new_attendance.error.description'),
          });

          return undefined;
        });

      if (newAttendance && newAttendance.id) {
        try {
          await api.post(
            `/zc/${environment?.id}/order/${newAttendance.id}/change_status/`,
            {
              next_status_code: statusCode,
            },
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }

        history.push(`/c/${environmentId}/callcenter/${newAttendance.id}`);

        handleCloseModal();
      }

      setIsLoading(false);
      return newAttendance;
    },
    {
      onSuccess: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
      onError: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
    },
  );

  const [isLoadingContactsList, setIsLoadingContactsList] = useState(false);
  const [contactsList, setContactsList] = useState<SelectOptions[]>(
    contactExternal ? [contactExternal] : [],
  );

  const handleSetContactsList = (data: SelectOptions[]) => {
    const selectedContact = data[0];
    setContactsList(() => data);
    form.setFieldsValue({ contactId: selectedContact.value });
  };

  const getContactList = useMutation(
    async (quickSearch: string): Promise<void> => {
      if (!environment) {
        return;
      }

      setIsLoadingContactsList(true);

      let filter = null;
      if (quickSearch) {
        if (quickSearch.match(/\d/) != null) {
          filter = { number__icontains: quickSearch };
        } else {
          filter = { name__icontains: quickSearch };
        }
      }
      await api
        .get<ContactResponseProps>(`/zc/${environment.id}/client/`, {
          params: {
            offset: 0,
            limit: 50,
            ...(filter && { ...filter }),
          },
        })
        .then(response => {
          const { data } = response;
          const formattedContactsList: SelectOptions[] = data.results.map(
            contact => ({
              name: contact.name || contact.number,
              label: contact.name || contact.number,
              value: contact.id,
            }),
          );

          setContactsList(() => [...formattedContactsList]);
        });

      setIsLoadingContactsList(false);
    },
    {
      onSuccess: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
      onError: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
    },
  );

  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const handleSetResponsibleSelected = (responsibleId: number) => {
    form.setFieldsValue({ responsibleId });
  };

  if (
    !environment ||
    isLoadingResponsiblesList ||
    !responsiblesList ||
    isLoadingStatus ||
    !statusList
  ) {
    return <></>;
  }

  const formattedResponsiblesList = responsiblesList.map(responsible => ({
    name: responsible.name,
    label: responsible.name,
    value: responsible.id,
  }));

  const onFinish = (newAttendance: ICreateAttendance) => {
    createAttendance.mutateAsync(newAttendance);
  };

  const handleCloseModal = () => {
    form.resetFields();

    onCloseModal();
  };

  return (
    <>
      <Modal
        title={intl.get('modal_new_attendance.title')}
        visible={isOpenModal}
        onCancel={() => handleCloseModal()}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...(responsibleIdExternal && {
              responsibleId: responsibleIdExternal,
            }),
            ...(contactExternal && { contactId: contactExternal.value }),
            statusCode: statusCodeExternal || statusList[0].code,
          }}
          onFinish={onFinish}
        >
          <Row gutter={8} style={{ alignItems: 'center' }}>
            <Col span={22}>
              <Form.Item
                label={intl.get(
                  'attendance.filters.responsible_for_the_service',
                )}
                name="responsibleId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={intl.get(
                    'attendance.filters.responsible_for_the_service_placeholder',
                  )}
                  filterSort={(optionA, optionB) =>
                    optionA?.name
                      .toLowerCase()
                      .localeCompare(optionB.name.toLowerCase())
                  }
                  options={formattedResponsiblesList}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <ModalNewResponsible
                type="icon"
                onSetResponsibleSelected={handleSetResponsibleSelected}
                isOpenAfterCreated={false}
              />
            </Col>
          </Row>

          <Row gutter={8} style={{ alignItems: 'center' }}>
            <Col span={22}>
              <Form.Item
                label={intl.get(
                  'attendance.filters.contact_for_the_attendance',
                )}
                name="contactId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={
                    isLoadingContactsList ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : null
                  }
                  onSearch={value => {
                    getContactList.mutateAsync(value);
                  }}
                  placeholder={intl.get(
                    'attendance.filters.contact_for_the_attendance_placeholder',
                  )}
                  options={contactsList}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <NewContactModal
                type="icon"
                isOpenAfterCreated={false}
                onSetContactsList={handleSetContactsList}
              />
            </Col>
          </Row>

          <Form.Item
            label={intl.get('attendance.filters.status')}
            name="statusCode"
          >
            <Select
              placeholder={intl.get('attendance.filters.status_placeholder')}
            >
              {statusList.map(state => (
                <Select.Option key={state.code} value={`${state.code}`}>
                  {state.code} - {state.status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {intl.get('modal_new_attendance.create_attendance')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalNewAttendance;
