/* eslint-disable import/order */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './hooks/auth';

import 'react-toastify/dist/ReactToastify.css';
import './styles/customize-antd.css';
import GlobalStyles from './styles/global';

import Routes from './routes';
import { LocalesProvider } from './hooks/locales';

const App: React.FC = () => (
  <Router>
    <LocalesProvider>
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <ToastContainer autoClose={3000} />
      <GlobalStyles />
    </LocalesProvider>
  </Router>
);

export default App;
