import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from './environments/environment';

interface ResponseQRCode {
  allow_qrcode: boolean;
  html_tag: string | null;
}

interface WhatsAppDeviceContextData {
  qrCode: string | null;
  isConnected: boolean | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  isFetching: boolean;
}
const WhatsAppDeviceContext = createContext<WhatsAppDeviceContextData>(
  {} as WhatsAppDeviceContextData,
);

const WhatsAppDeviceProvider: React.FC = ({ children }) => {
  const { environment, isFetching: isFetchingEnvironment } = useEnvironment();

  const [isConnected, setIsConnected] = useState<boolean | undefined>(
    undefined,
  );
  const [qrCode, setQRCode] = useState<string | null>(null);

  const { isLoading, isError, isFetching, refetch } = useQuery(
    [`qrcode`, environment, isFetchingEnvironment],
    async () => {
      if (!environment) {
        return undefined;
      }

      const response = await api.get<ResponseQRCode>(
        `zc/config/${environment.id}/qrcode/`,
      );

      const { data } = response;

      if (data.html_tag && data.html_tag.match(/image/) !== null) {
        setQRCode(data.html_tag.replace(/image/, 'img  width="100%" '));
        setIsConnected(false);
      } else {
        setIsConnected(true);
      }

      return data;
    },
    {
      refetchInterval: 5000,
    },
  );

  return (
    <WhatsAppDeviceContext.Provider
      value={{
        qrCode,
        isConnected,
        isLoading,
        isError,
        isFetching,
        refetch,
      }}
    >
      {children}
    </WhatsAppDeviceContext.Provider>
  );
};

function useWhatsAppDevice(): WhatsAppDeviceContextData {
  const context = useContext(WhatsAppDeviceContext);

  if (!context) {
    throw new Error(
      'useWhatsAppDevice must be used within an WhatsAppDeviceProvider',
    );
  }

  return context;
}

export { WhatsAppDeviceProvider, useWhatsAppDevice };
