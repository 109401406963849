import { Divider, Skeleton } from 'antd';
import React from 'react';

import * as styles from './styles';
import { useAttendance } from '~/hooks/attendances/attendance';
import DrawerContact from '~/components/global/molecules/DrawerContact';
import DrawerAttendance from '../../molecules/DrawerAttendance';
import DrawerAnsweredForm from '../../molecules/DrawerAnsweredForm';
import ChangeStatusChatBot from '~/components/global/atoms/ChangeStatusChatBot';

import { ContactProvider } from '~/hooks/contacts/contact';
import DrawerAttendanceLog from '../../molecules/DrawerAttendanceLog';

const ChatTitleBar: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  if (isLoading || !attendance) {
    return (
      <styles.Container>
        <div>
          <Skeleton.Input active style={{ width: 150 }} />
        </div>
        <div>
          <Skeleton.Input active style={{ width: 200 }} />
        </div>
      </styles.Container>
    );
  }

  return (
    <styles.Container>
      <div />
      <styles.Menu>
        <DrawerAttendance />
        <ContactProvider contactId={attendance.contact.id}>
          <DrawerContact />
        </ContactProvider>
        <DrawerAnsweredForm />
        <DrawerAttendanceLog />
        <Divider type="vertical" />

        <ChangeStatusChatBot />
      </styles.Menu>
    </styles.Container>
  );
};

export default ChatTitleBar;
