import React from 'react';

import { Button, Divider } from 'antd';

import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Container, Nav, Children } from './styles';
import SelectEnvironmentModal from '../../molecules/SelectEnvironmentModal';

interface NavBarProps {
  title?: string;
  isBack?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ title, isBack = false, children }) => {
  const history = useHistory();
  return (
    <Container>
      <Nav>
        {isBack && (
          <Button
            type="text"
            size="small"
            onClick={() => history.goBack()}
            icon={<ArrowLeftOutlined />}
          />
        )}

        <div>{title ? <b>{title}</b> : <SelectEnvironmentModal />}</div>
        <Divider type="vertical" />

        <Children>{children}</Children>
      </Nav>
    </Container>
  );
};

export default NavBar;
