import React from 'react';
import { Badge } from 'antd';

import { useUnreadMessages } from '~/hooks/messages/unreadMessages';

interface UnreadMessagesProps {
  contactId: number;
}
const UnreadMessages: React.FC<UnreadMessagesProps> = ({ contactId }) => {
  const { unreadMessagesList, isLoading } = useUnreadMessages();

  if (isLoading || !unreadMessagesList) {
    return <></>;
  }

  return (
    <>
      {unreadMessagesList[contactId] && !isLoading && (
        <Badge count={unreadMessagesList[contactId]} />
      )}
    </>
  );
};

export default UnreadMessages;
