import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  width: 100%;
  flex: 0 0 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background: #fff;
  border-top: var(--border);
`;

export const Menu = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  flex: 1;

  .icon {
    font-size: 1.2rem;
  }

  a {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    color: rgba(0, 0, 0, 0.65);
    font-size: 0.75rem;
  }
`;
