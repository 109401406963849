import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: #fff;

  &:hover {
    background: #f5f5f5;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .tag {
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-titles {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;

    .ant-timeline-item {
      padding-bottom: 0;
    }
  }
`;
