import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useUnreadMessages } from '~/hooks/messages/unreadMessages';

const SEO: React.FC = () => {
  const { totalUnreadMessages } = useUnreadMessages();
  const title = 'ZapForm';
  useEffect(() => {
    document.title = `${
      totalUnreadMessages > 0 ? `(${totalUnreadMessages}) ` : ''
    }${title}`;
  }, [totalUnreadMessages]);
  return <Helmet title={title} />;
};

export default SEO;
