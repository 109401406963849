/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { Collapse, Image, Typography } from 'antd';
import React from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import Address from '~/components/global/atoms/Address';

import BaseField from '~/components/global/atoms/BaseField';
import { formatterLabel } from './solutionTemp.js';

interface FormAttendanceViewTempProps {
  questions: any;

  updateAndSendField: any;
}

const FormAttendanceViewTemp: React.FC<FormAttendanceViewTempProps> = ({
  questions,

  updateAndSendField,
}) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Image.PreviewGroup>
          {Object.entries(questions).map((question: any, questionIndex) => {
            if (Array.isArray(question[1])) {
              return (
                <div key={questionIndex}>
                  <Typography.Title
                    ellipsis
                    level={5}
                    style={{ marginBottom: '0' }}
                  >
                    {formatterLabel(question[0])}
                  </Typography.Title>
                  <Collapse
                    defaultActiveKey={['0']}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    {question[1].map(
                      (questionChildren: any, questionChildrenindex) => (
                        <Collapse.Panel
                          header={`${formatterLabel(
                            Object.keys(questionChildren)[0],
                          )}: ${Object.values(questionChildren)[0]}`}
                          key={questionChildrenindex}
                        >
                          <FormAttendanceViewTemp
                            questions={questionChildren}
                            updateAndSendField={updateAndSendField}
                          />
                        </Collapse.Panel>
                      ),
                    )}
                  </Collapse>
                </div>
              );
            }
            if (question[1]?._type === 'location') {
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                >
                  <Address
                    address={question[1].text}
                    lat={question[1].lat}
                    lng={question[1].lng}
                  />
                </BaseField>
              );
            }

            if (
              question[1]?._type === 'image' ||
              question[1]?._type === 'document' ||
              question[1]?._type === 'audio' ||
              question[1]?._type === 'video'
            ) {
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                  value={question[1]}
                  url={question[1].url}
                  name={question[0]}
                  type={question[1]?._type}
                  editable
                  onChange={field => updateAndSendField(field)}
                />
              );
            }

            if (typeof question[1] === 'object') {
              return (
                <>
                  <BaseField
                    key={questionIndex}
                    label={formatterLabel(question[0])}
                    value=""
                    name={question[0]}
                    editable
                    onChange={field => updateAndSendField(field)}
                  />
                </>
              );
            }

            return (
              <BaseField
                key={questionIndex}
                label={formatterLabel(question[0])}
                value={question[1]}
                name={question[0]}
                editable
                onChange={field => updateAndSendField(field)}
              />
            );
          })}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default FormAttendanceViewTemp;
