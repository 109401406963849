import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { useWhatsAppWeb } from './whatsAppWeb';

export interface ContactResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      number: string;
      address: string;
    },
  ];
}

export interface ContactProps {
  id: number;
  name: string;
  id_name: string;
  number: string;
  address: string;
}

interface ChatContactsContextData {
  contactsList: ContactProps[];
  totalContacts: number | null;
  hasMore: boolean;
  moreContacts: () => void;
  isLoading: boolean;
  refetch: () => void;
}
const ChatContactsContext = createContext<ChatContactsContextData>(
  {} as ChatContactsContextData,
);

const ChatContactsProvider: React.FC = ({ children }) => {
  const [contactsList, setContactsList] = useState<ContactProps[]>([]);
  const [totalContacts, setTotalContacts] = useState<number | null>(null);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(false);

  const { environment } = useEnvironment();

  const { quickSearch, settings } = useWhatsAppWeb();

  const formatSorting = (): string => {
    if (!settings.sortBy) {
      return '';
    }
    let result = '';
    result += settings.sortBy.sort === 'asc' ? '' : '-';
    result += settings.sortBy.by;

    return result;
  };

  const { isLoading, refetch } = useQuery(
    [`chatContactsList`, limit, environment?.id, quickSearch, settings],
    async () => {
      if (!environment) {
        return;
      }
      const response = await api.get(`/zc/${environment.id}/client/`, {
        params: {
          offset: 0,
          limit,
          ...(quickSearch
            ? quickSearch.match(/\d/) != null
              ? { number__icontains: quickSearch }
              : { name__icontains: quickSearch }
            : {}),
          ...(settings.sortBy && { order_by: formatSorting() }),
        },
      });

      const { data } = response;
      formatterListContact(data);
    },
  );

  const formatterListContact = (data: ContactResponseProps) => {
    setHasMore(!!data.next);
    setTotalContacts(data.count);

    const contactsListResponse = data.results.map(contact => {
      const contactResponse: ContactProps = {
        ...contact,
        id_name: `#${contact.id} - ${contact.name}`,
      };

      return contactResponse;
    });

    setContactsList(contactsListResponse);
  };

  const moreContacts = useCallback(() => {
    setLimit(limitOld => limitOld + 20);
  }, []);

  return (
    <ChatContactsContext.Provider
      value={{
        contactsList,
        totalContacts,
        hasMore,
        moreContacts,
        isLoading,
        refetch,
      }}
    >
      {children}
    </ChatContactsContext.Provider>
  );
};
function useChatContacts(): ChatContactsContextData {
  const context = useContext(ChatContactsContext);

  if (!context) {
    throw new Error(
      'useChatContacts must be used within an ChatContactsProvider',
    );
  }

  return context;
}

export { ChatContactsProvider, useChatContacts };
