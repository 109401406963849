let id = 0;
function uuidv4() {
  id++;
  return id;
}

const formatterQuestions = (questions) => {
  let newQuestions = {}
  Object.entries(questions).map((question) => {

    const title = `$#${uuidv4()}$#${question[0]}`;
    const value = question[1];

    if (Array.isArray(value)) {
      newQuestions = {...newQuestions,
        [title]: value.map((questionChildren) => formatterQuestions(questionChildren)),
      };
    }else {
      newQuestions = {...newQuestions,
        [title]: value,
      };
    }


  });

  return newQuestions;
};

const updateQuestion = (questions, key, newValue) => {
  let newQuestions = {}
  Object.entries(questions).map((question) => {

    const title = question[0];
    const value = question[1];


    if (Array.isArray(value)) {
      newQuestions = {...newQuestions,
        [title]: value.map((questionChildren) => updateQuestion(questionChildren, key, newValue)),
      };
    }
    else if(title === key){
      newQuestions = {...newQuestions,
        [title]: newValue,
      };
    }
    else {
      newQuestions = {...newQuestions,
        [title]: value,
      };
    }
  });

  return newQuestions;

};

const formatterSendQuestions = (questions) => {
  let newQuestions = {}
  Object.entries(questions).map((question) => {

    const title = question[0].replace(/\$#[0-9]+\$#/, "");
    const value = question[1];

    if (Array.isArray(value)) {
      newQuestions = {...newQuestions,
        [title]: value.map((questionChildren) => formatterSendQuestions(questionChildren)),
      };
    }else {
      newQuestions = {...newQuestions,
        [title]: value,
      };
    }


  });

  return newQuestions;
};

const formatterLabel = (label) =>{
  return label.replace(/\$#[0-9]+\$#/, "");
}

export { formatterQuestions, updateQuestion, formatterSendQuestions, formatterLabel }
