import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

export interface StatusProps {
  code: string;
  status: string;
}

interface StatusContextData {
  statusList?: StatusProps[];
  isLoading: boolean;
}

export const StatusContext = createContext<StatusContextData>(
  {} as StatusContextData,
);

const StatusProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();

  const { data: statusList, isLoading } = useQuery(
    [`statusList`, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get(`zc/config/${environment.id}/`);
      const { data } = response;
      const statusListResponse: StatusProps[] = data.status;
      return statusListResponse;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <StatusContext.Provider value={{ statusList, isLoading }}>
      {children}
    </StatusContext.Provider>
  );
};
function useStatus(): StatusContextData {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useStatus must be used within an StatusProvider');
  }

  return context;
}

export { StatusProvider, useStatus };
