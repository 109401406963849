import React from 'react';

import { ContactProvider } from '~/hooks/contacts/contact';
import ContactComponent from '~/components/WhatsAppWeb/organisms/Contact';

const Contact: React.FC = () => {
  return (
    <>
      <ContactProvider>
        <ContactComponent />
      </ContactProvider>
    </>
  );
};

export default Contact;
