import React, { createContext, useContext, useState } from 'react';

export interface SettingsProps {
  title?: Array<'contact.avatar' | 'contact.id_name' | 'contact.number'>;
  subTitles?: Array<'contact.address'>;
  sortBy?: {
    sort: 'asc' | 'desc';
    by: 'last_msg' | 'name' | 'number_unread_msgs';
  };
}

interface WhatsAppWebContextData {
  settings: SettingsProps;
  setNewSettings: (newSettingsInput: SettingsProps) => void;
  resetSettings: () => void;
  quickSearch?: string;
  setNewQuickSearch: (newQuickSearch: string) => void;
}
const WhatsAppWebContext = createContext<WhatsAppWebContextData>(
  {} as WhatsAppWebContextData,
);

const WhatsAppWebProvider: React.FC = ({ children }) => {
  const defaultSettings: SettingsProps = {
    title: ['contact.avatar', 'contact.number'],
    subTitles: [],
    sortBy: { sort: 'desc', by: 'last_msg' },
  };

  const [quickSearch, setQuickSearch] = useState<string>('');
  const [settings, setSettings] = useState<SettingsProps>(() => {
    const settingsLocalStorage = localStorage.getItem(
      '@ZapForm:whatsappwebettings',
    );

    if (settingsLocalStorage) {
      const formattedSettings: SettingsProps = JSON.parse(settingsLocalStorage);

      if (
        formattedSettings.title &&
        formattedSettings.subTitles &&
        formattedSettings.sortBy
      ) {
        return formattedSettings;
      }
    }

    localStorage.setItem(
      '@ZapForm:whatsappwebettings',
      JSON.stringify(defaultSettings),
    );

    return defaultSettings;
  });

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  const setNewSettings = (newSettingsInput: SettingsProps) => {
    localStorage.setItem(
      '@ZapForm:whatsappwebettings',
      JSON.stringify(newSettingsInput),
    );
    setSettings(newSettingsInput);
  };

  const resetSettings = () => {
    localStorage.setItem(
      '@ZapForm:whatsappwebettings',
      JSON.stringify(defaultSettings),
    );
    setSettings(defaultSettings);
  };

  return (
    <WhatsAppWebContext.Provider
      value={{
        settings,
        setNewSettings,
        resetSettings,
        quickSearch,
        setNewQuickSearch,
      }}
    >
      {children}
    </WhatsAppWebContext.Provider>
  );
};
function useWhatsAppWeb(): WhatsAppWebContextData {
  const context = useContext(WhatsAppWebContext);

  if (!context) {
    throw new Error(
      'useWhatsAppWeb must be used within an WhatsAppWebProvider',
    );
  }

  return context;
}

export { WhatsAppWebProvider, useWhatsAppWeb };
