import React from 'react';

import { Result, Button } from 'antd';

import { Link } from 'react-router-dom';
import { Container } from './styles';

const Page404: React.FC = () => {
  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle="Desculpe, essa página não existe."
        extra={
          <Link to="/">
            <Button type="primary">Voltar pra o ZapForm</Button>
          </Link>
        }
      />
    </Container>
  );
};

export default Page404;
