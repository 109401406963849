import intl from 'react-intl-universal';
import { Form, FormInstance } from 'antd';
import React from 'react';
import PhoneInputComponent, {
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { NamePath } from 'antd/lib/form/interface';
import { useMutation } from 'react-query';
import { useLocales } from '~/hooks/locales';
import apiWhatsApp from '~/services/apiWhatsApp';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';

interface PhoneInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  name: NamePath;
  keyList?: string;
  onValidated?: (phoneNumber: string | undefined) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  form,
  name,
  keyList,
  onValidated,
}) => {
  const { currentLocale } = useLocales();
  const { mutateAsync: validatePhoneNumber, isLoading } = useMutation(
    async (phoneNumber: string): Promise<string | undefined> => {
      const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);

      try {
        const response = await apiWhatsApp.post(
          '/clients/validatePhoneNumber',
          {
            phoneNumber: formattedPhoneNumber,
          },
        );

        const { data } = response;

        return data.user;
      } catch (error) {
        return undefined;
      }
    },
  );

  return (
    <Form.Item
      label={intl.get('contact.number')}
      name={name}
      hasFeedback={isLoading}
      rules={[
        {
          required: true,
          message: intl.get('contact.create.number_validation'),
        },
        () => ({
          async validator(rule, phone) {
            if (phone && isPossiblePhoneNumber(phone)) {
              const phoneValidate = await validatePhoneNumber(phone);

              if (phoneValidate) {
                const formattedPhoneValidate = `+${phoneValidate}`;

                if (keyList) {
                  const formFields = form.getFieldsValue();

                  const [key, type] = name as Array<string>;

                  const fields = formFields[keyList];

                  Object.assign(fields[key], {
                    [type]: formattedPhoneValidate,
                  });

                  form.setFieldsValue({ [keyList]: fields });

                  if (onValidated) {
                    onValidated(phoneValidate);
                  }
                } else {
                  if (onValidated) {
                    onValidated(phoneValidate);
                  }
                  form.setFieldsValue({
                    [String(name)]: formattedPhoneValidate,
                  });
                }

                return Promise.resolve();
              }
              if (onValidated) {
                onValidated(undefined);
              }

              return Promise.reject(
                new Error(intl.get('contact.create.error_validate_whatsapp')),
              );
            }
            return Promise.reject(
              new Error(intl.get('contact.create.number_error')),
            );
          },
        }),
      ]}
    >
      <PhoneInputComponent
        placeholder={intl.get('contact.number')}
        defaultCountry={currentLocale === 'pt-BR' ? 'BR' : undefined}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
      />
    </Form.Item>
  );
};

export default PhoneInput;
