import { MessageOutlined } from '@ant-design/icons';
import { Button, Drawer, Select } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import BaseField from '~/components/global/atoms/BaseField';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
// import { Container } from './styles';

const DrawerAttendance: React.FC = () => {
  const {
    attendance,
    isLoading,
    updateResponsible,
    moveAttendance,
  } = useAttendance();
  const { statusList } = useStatus();
  const { responsiblesList } = useResponsibles();

  const [isVisible, setIsVisible] = useState(false);

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  if (isLoading || !attendance || !statusList || !responsiblesList) {
    return (
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<MessageOutlined />}
        loading
      />
    );
  }

  return (
    <>
      <Button
        type="text"
        size="large"
        shape="circle"
        icon={<MessageOutlined />}
        onClick={() => {
          showDrawer();
        }}
      />

      <Drawer
        title={intl.get('call_center.title_bar.attendance_information')}
        placement="bottom"
        height="100%"
        onClose={onClose}
        visible={isVisible}
      >
        <BaseField
          label={intl.get('attendance.id')}
          value={attendance.id}
          copyable
        />

        <BaseField
          label={intl.get('attendance.order_summary')}
          value={attendance.order_summary}
        />

        <BaseField label={intl.get('attendance.status.title')}>
          <Select
            value={attendance.status?.code}
            onChange={value => {
              moveAttendance(value);
            }}
            style={{ width: '100%' }}
          >
            {statusList.map(state => (
              <Select.Option key={state.code} value={`${state.code}`}>
                {state.code} - {state.status}
              </Select.Option>
            ))}
          </Select>
        </BaseField>

        <BaseField label={intl.get('attendance.responsible.title')}>
          <Select
            value={attendance.responsible?.id}
            onChange={value => {
              updateResponsible(Number(value));
            }}
            style={{ width: '100%' }}
          >
            {responsiblesList.map(responsible => (
              <Select.Option key={responsible.id} value={responsible.id}>
                {responsible.name}
              </Select.Option>
            ))}
          </Select>
        </BaseField>

        <BaseField
          label={intl.get('attendance.creation_date')}
          value={attendance.time_createdFormatted}
        />

        <BaseField
          label={intl.get('attendance.creation_date_there')}
          value={attendance.time_createdThere}
        />

        <BaseField
          label={intl.get('attendance.date_of_status_change')}
          value={attendance.time_statusFormatted}
        />

        <BaseField
          label={intl.get('attendance.deadline')}
          value={attendance.time_deadlineFormatted}
        />

        <BaseField
          label={intl.get('attendance.deadline_expiration')}
          value={attendance.time_timeoutFormatted}
          style={{
            color:
              attendance.time_howIsTimeout === 'timeout'
                ? 'red'
                : attendance.time_howIsTimeout === 'insideTime'
                ? '#faad14'
                : 'none',
          }}
        />
      </Drawer>
    </>
  );
};

export default DrawerAttendance;
