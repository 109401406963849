import { Button } from 'antd';

import React from 'react';
import { FaRobot } from 'react-icons/fa';
import { useBot } from '~/hooks/bot/bot';
// import { Container } from './styles';

const ChangeStatusChatBot: React.FC = () => {
  const { isBotEnabled, updateBotStatus, isLoading } = useBot();

  if (isLoading || isBotEnabled === undefined) {
    return <Button type="text" size="large" loading={isLoading} />;
  }

  const handleBotActive = () => {
    updateBotStatus(!isBotEnabled);
  };

  return (
    <Button
      type="text"
      size="large"
      icon={
        <FaRobot color={isBotEnabled ? '#009b60' : '#ff4d4f'} size="1.4rem" />
      }
      onClick={() => {
        handleBotActive();
      }}
    />
  );
};

export default ChangeStatusChatBot;
