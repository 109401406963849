import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useWhatsAppWeb, SettingsProps } from '~/hooks/whatsAppWeb/whatsAppWeb';

const { Option, OptGroup } = Select;

const Settings: React.FC = () => {
  const { settings, setNewSettings, resetSettings } = useWhatsAppWeb();
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue(settings);
  }, [settings, form]);

  const onFieldsChange = (allValues: SettingsProps) => {
    setNewSettings(allValues);
  };

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <Button type="text" icon={<SettingOutlined />} onClick={showDrawer} />
      <Drawer
        title={intl.get('call_center.settings.title')}
        placement="top"
        height="100%"
        closable
        onClose={onClose}
        visible={isVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
        footer={
          <Button type="primary" onClick={onClose} block>
            {intl.get('buttons.save_settings')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) =>
            onFieldsChange(allValues)
          }
          initialValues={settings}
        >
          <Form.Item
            label={intl.get('call_center.settings.title_card')}
            name="title"
          >
            <Select mode="multiple" style={{ width: '100%' }}>
              <OptGroup label={intl.get('contact.title')}>
                <Option value="contact.avatar">
                  {intl.get('contact.avatar')}
                </Option>
                <Option value="contact.id_name">
                  {intl.get('contact.id_and_name')}
                </Option>
                <Option value="contact.number">
                  {intl.get('contact.number')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
          {/* <Form.Item
            label={intl.get('call_center.settings.sub_titles_card')}
            name="subTitles"
          >
            <Select mode="multiple" style={{ width: '100%' }}>
              <OptGroup label={intl.get('contact.title')}>
                <Option value="contact.address">
                  {intl.get('contact.address')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item> */}

          <Form.Item label={intl.get('sort_by.title')}>
            <Form.Item name={['sortBy', 'sort']}>
              <Select>
                <Option value="asc">{intl.get('sort_by.asc')}</Option>
                <Option value="desc">{intl.get('sort_by.desc')}</Option>
              </Select>
            </Form.Item>
            <Form.Item name={['sortBy', 'by']}>
              <Select placeholder={intl.get('sort_by.title')}>
                <Option value="last_msg">{intl.get('sort_by.last_msg')}</Option>
                <Option value="name">{intl.get('sort_by.name')}</Option>
                <Option value="number_unread_msgs">
                  {intl.get('sort_by.number_unread_msgs')}
                </Option>
              </Select>
            </Form.Item>
          </Form.Item>
        </Form>
        <Button
          type="ghost"
          onClick={() => {
            resetSettings();
          }}
          block
        >
          {intl.get('buttons.reset_settings')}
        </Button>
      </Drawer>
    </div>
  );
};

export default Settings;
