import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

export interface ResponsiblesResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      address: string;
    },
  ];
}

export interface ResponsiblesProps {
  id: number;
  name: string;
  id_name: string;
  address: string;
}

interface ResponsiblesContextData {
  pageSize: number;
  handleChangePageSize: (value: number) => void;
  responsiblesList?: ResponsiblesProps[];
  totalResponsibles: number;
  hasMore: boolean;
  moreResponsibles: (page: number, pageSize: number) => void;
  isLoading: boolean;
  refetch: () => void;
  isFetching: boolean;
}
const ResponsiblesContext = createContext<ResponsiblesContextData>(
  {} as ResponsiblesContextData,
);

interface ResponsiblesProviderProps {
  listAll?: boolean;
}
const ResponsiblesProvider: React.FC<ResponsiblesProviderProps> = ({
  listAll = false,
  children,
}) => {
  const { environment } = useEnvironment();

  const [totalResponsibles, setTotalResponsibles] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [limit, setLimit] = useState(pageSize);
  const [hasMore, setHasMore] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const formatListReponsible = async (
    data: ResponsiblesResponseProps,
  ): Promise<ResponsiblesProps[]> => {
    const formattedResponsiblesslist = data.results.map(responsible => {
      return {
        ...responsible,
        id_name: `#${responsible.id} - ${responsible.name}`,
      };
    });

    return formattedResponsiblesslist;
  };

  const { data: responsiblesList, refetch, isFetching } = useQuery(
    [`responsiblesList`, pageSize, limit, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }

      const response = await api.get<ResponsiblesResponseProps>(
        `/zc/${environment.id}/location/`,
        {
          params: {
            ...(listAll
              ? {
                  offset: 0,
                  limit: 200,
                }
              : {
                  offset: limit - pageSize,
                  limit: pageSize,
                }),
          },
        },
      );

      const { data } = response;

      setHasMore(!!data.next);
      setTotalResponsibles(data.count);

      const formattedResponsiblesslist = await formatListReponsible(data);

      setIsLoading(false);
      return formattedResponsiblesslist;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const moreResponsibles = useCallback((page: number, size: number) => {
    setLimit(page * size);
    setPageSize(size);
  }, []);

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  return (
    <ResponsiblesContext.Provider
      value={{
        totalResponsibles,
        responsiblesList,
        hasMore,
        moreResponsibles,
        isLoading,
        refetch,
        isFetching,
        pageSize,
        handleChangePageSize,
      }}
    >
      {children}
    </ResponsiblesContext.Provider>
  );
};
function useResponsibles(): ResponsiblesContextData {
  const context = useContext(ResponsiblesContext);

  if (!context) {
    throw new Error(
      'useResponsibles must be used within an ResponsiblesProvider',
    );
  }

  return context;
}

export { ResponsiblesProvider, useResponsibles };
