import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;

  padding: 0 1rem;
  background: #fff;
  border-bottom: var(--border);
  border-radius: var(--radius);
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const Children = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;
